<!--header refactor here-->
<header role="main" class="container-fluid ">
  <div *ngIf="!!currentRoute
              && !currentRoute.includes('/page/')
              && !existsSessionUser"
       class="row d-flex ml-4 mr-4 mt-4 mb-2 flex-nowrap ">
    <div class="col-md-2 offset-md-1">
      <img
        id="logo"
        [src]="this.safeLogoSource"
        alt="logo"
        style="max-height: var(--logo-height, 50px)"
      >
    </div>
    <div class="px-3 py-3 pl-4 col col-md-9">
      <h3 class="overflowtext">{{'APPLICATION.SFK-ONLINE'|translate}} - {{'APPLICATION.SFK-MODUL'|translate}}</h3>
    </div>
  </div>
  <section *ngIf="  !!currentRoute
                    && !currentRoute.includes('/authentication/')
                    && !currentRoute.includes('/information/')
                    && existsSessionUser "
  >
    <app-navbar-responsive
      [moduleEnabled]="moduleEnabled"
      [roleEnabled]="roleEnabled"
      [benutzer]="benutzer"
      [millisecUntilLogout$]="millisecUntilLogout$"
      [endDateForLogout$]="endDateForLogout$"
      [loggedoutMessageVisible$]="loggedoutMessageVisible$"
    ></app-navbar-responsive>
  </section>
</header>

<div class="container" >
  <router-outlet></router-outlet>
</div>

<app-footer-custom></app-footer-custom>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
  [fullScreen] = "true"
  [showSpinner]="loading"
>
</ngx-spinner>

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CustomToastService {
  constructor(private toastr: ToastrService, private router: Router) {}

  showSuccess(message: string, title = 'Ausführung erfolgreich') {
    this.toastr.success(message, title, {
			timeOut: 10000,
      closeButton: true,
      positionClass: 'toast-top-right'
    });
  }

  showInfo(message: string, title = 'Information', route = '#') {
    this.toastr
      .info(message, title, {
				timeOut: 20000,
        closeButton: true,
        positionClass: 'toast-top-right'
      })
      .onTap.subscribe(() =>  this.router.navigate([route]));
		const toast = this.toastr.error(message, title, {
			closeButton: true,
			positionClass: 'toast-top-right',
			timeOut: 100,
			tapToDismiss: true
		});
		toast.onTap.subscribe(() => this.router.navigate([route]));
		toast.onShown.subscribe(() => {});
  }

  showWarning(message: string, title = 'Warnung'){
    this.toastr.warning(message, title, {
      timeOut: 10000,
      closeButton: true,
      positionClass: 'toast-top-right'
    });
  }

  showError(message: string, title = 'Fehler') {
    this.toastr.error(message, title, {
			timeOut: 20000,
      closeButton: true,
      positionClass: 'toast-top-right',
      enableHtml: true
    });
  }

  showPermanentError(message: string, title = 'Fehler') {
    this.toastr.error(message, title, {
      disableTimeOut: true,
      tapToDismiss: false,
      closeButton: false,
      positionClass: 'toast-top-right'
    });
  }

  isDuplicate(message: string, type: string): boolean {
    let title = '';
    if (type === 'success') {
      title = 'Ausführung erfolgreich';
    } else if (type === 'info') {
      title = 'Information';
    } else if (type === 'error') {
      title = 'Fehler';
    }

    let duplicate = this.toastr.findDuplicate(title, message, false, false);
    return duplicate !== null;
  }

}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {RegistrierungComponent} from "./registrierung/registrierung.component";
import {AktivierungComponent} from "./aktivierung/aktivierung.component";
import {EinladungComponent} from "./einladung/einladung.component";
import {KennwortVergessenComponent} from "./kennwort-vergessen/kennwort-vergessen.component";
import {KennwortZuruecksetzenComponent} from "./kennwort-zuruecksetzen/kennwort-zuruecksetzen.component";
import {KennwortAendernComponent} from "./kennwort-aendern/kennwort-aendern.component";
import {EmailAendernComponent} from "./email-aendern/email-aendern.component";
import {UnlockComponent} from "./unlock/unlock.component";
import {DeadendComponent} from "./deadend/deadend.component";

export const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'registrierung',
        component: RegistrierungComponent
      },
      {
        path: 'aktivierung',
        component: AktivierungComponent
      },
      {
        path: 'einladung-aktivieren',
        component: EinladungComponent
      },
      {
        path: 'kennwort-vergessen',
        component: KennwortVergessenComponent
      },
      {
        path: 'kennwort-zuruecksetzen',
        component: KennwortZuruecksetzenComponent
      },
      { //not used?
        path: 'mail-aenderung',
        component: EmailAendernComponent
      },
      { //not used?
        path: 'kennwort-aenderung',
        component: KennwortAendernComponent
      },
      {
        path: 'unlock',
        component: UnlockComponent
      },
      {
        path: 'info',
        component: DeadendComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}

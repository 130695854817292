import {Component, OnInit, SecurityContext} from '@angular/core';
import {ConfirmComponent} from 'src/app/shared/confirm/confirm.component';
import {TextbausteinDTO} from "../../../../models/textbaustein/TextbausteinDTO";
import {TextbausteinService} from "../../../../@core/services/textbaustein/textbaustein.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {catchError} from "rxjs/operators";
import {faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {CompositMessageService} from "../../../../@core/services/compositMessage/compositMessage.service";
import {TranslateService} from "@ngx-translate/core";
import {EMPTY} from "rxjs";
import {CustomToastService} from "../../../../@core/utils/custom-toast.service";
import {TextareaComponent} from "../../../../shared/form/textarea/textarea.component";

/**
 * deprecated since 2.1.7
 * ASM standalone ("hasSachbearbeiterbackend=false") is no longer supported
 */
@Component({
  selector: 'app-textbausteine-detail',
  templateUrl: './textbausteine-detail.component.html',
  styleUrls: ['./textbausteine-detail.component.css']
})
export class TextbausteineDetailComponent implements OnInit {

  public textbausteinForm: UntypedFormGroup;
  textbaustein: TextbausteinDTO;
  textbausteinId: string;
  textbausteinList: TextbausteinDTO[];

  entityNameTranslationKey: string = 'TEXTBAUSTEIN';

  faSave = faSave;
  faDelete = faTrash;

  containsSpecialChars: boolean = false;
  isIntendedAsHTML: boolean = false;
  containsLegalSyntax: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private customToastService: CustomToastService,
    public translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private textbausteinService: TextbausteinService,
    private modalService: NgbModal,
    private compositMessageService: CompositMessageService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {

    this.textbausteinForm = this.fb.group({
      key: ['',Validators.required],
      value: ['',Validators.required],
    });

    this.textbausteinId = this.route.snapshot.paramMap.get('id');
    if(this.route.snapshot.paramMap.get('id') && this.route.snapshot.paramMap.get('id') !== '' &&
      this.route.snapshot.paramMap.get('id')!==null){
      this.textbausteinService.getTextbaustein(this.textbausteinId).subscribe((
          data: TextbausteinDTO) =>
        {
          this.textbaustein = { ...data };
          if(!(!this.textbaustein)){
            this.textbausteinForm.patchValue({
              key: this.textbaustein.key,
              value: this.textbaustein.value,
            });
          }
        }
      );
    }
    this.textbausteinService.getEmptyTextbausteine().subscribe(tb => {
      this.textbausteinList = tb.sort((a,b) => a.key.localeCompare(b.key));
    });
  }

  saveOrUpdate(){ // Textbaustein only gets patches, no saves
    if(!this.textbausteinForm.valid){
      this.customToastService.showError(this.compositMessageService.createIncompleteMessage(this.entityNameTranslationKey));
      return;
    }
    this.textbausteinContainsSpecialChars().then( () =>
    {
      if (this.containsSpecialChars) {
        this.showDecisionModalHTML().then( () =>
        {
          if(this.isIntendedAsHTML) {
            this.textbausteinContainsLegalSyntax().then(() => {
              if (!this.containsLegalSyntax) {
                this.customToastService.showError(this.compositMessageService.textbausteinBadSyntax());
              } else {
                this.customToastService.showSuccess(this.compositMessageService.textbausteinGoodSyntax());
                this.textbausteinIsSafe().then((isSafeContent) => {
                  if (!isSafeContent) {
                    this.customToastService.showError(this.compositMessageService.textbausteinUnsafe());
                    return;
                  }
                  this.customToastService.showSuccess(this.compositMessageService.textbausteinSafe());
                  if (this.route.snapshot.paramMap.get('id') !== null && this.route.snapshot.paramMap.get('id') !== '') {
                    this.update(this.route.snapshot.paramMap.get('id'));
                  }
                }).catch((error) => {
                  console.log(error);
                });
              }
            });
          } else {
            this.customToastService.showError(this.compositMessageService.textbausteinSpecialCharsErr());
          }
        });
      } else {
        if(this.route.snapshot.paramMap.get('id')!==null && this.route.snapshot.paramMap.get('id')!== ''){
          this.update(this.route.snapshot.paramMap.get('id'));
        }
      }
    });

  }

  textbausteinContainsSpecialChars(): Promise<boolean> {
    return new Promise((resolve) => {
      let input = this.getFormItem('value').value;
      let regExp = /[<>$]/g;
      this.containsSpecialChars = regExp.test(input);
      resolve(true);
    });
  }

  textbausteinContainsLegalSyntax(): Promise<boolean> {
    return new Promise((resolve) => {
      let parser = new DOMParser();
      let input = this.getFormItem('value').value;
      const doc = parser.parseFromString(input,'application/xml');
      const errorNode = doc.querySelector('parsererror');
      if(errorNode){
        this.containsLegalSyntax = false;
        console.log(errorNode);
      } else {
        this.containsLegalSyntax = true;
      }
      resolve(true);
    });
  }

  textbausteinIsSafe(): Promise<boolean> {
    return new Promise((resolve) => {
      const FIELD = "value"
      // this is possibly pre-sanitized since .value comes from angular
      let inputValue = this.getFormItem(FIELD).value;
      // access to the raw unsanitized value by using pure JavaScript's getElementById
      let rawValue = (document.getElementById(TextareaComponent.getHtmlTextAreaIdByFieldName(FIELD)) as HTMLTextAreaElement).value;

      let isSafe = true;
      if (rawValue !== inputValue){
        isSafe = false;
      }

      let safeHtmlPart: SafeHtml = this.sanitizer.sanitize(SecurityContext.HTML, inputValue);
      if (safeHtmlPart !== inputValue){
        isSafe = false;
      }

      this.textbausteinForm.patchValue({value: safeHtmlPart});
      resolve(isSafe);
    });
  }

  getFormItem(s: string) {
    return this.textbausteinForm?.get(s);
  }

  update(id: string)  {
    this.textbausteinService.updateTextbaustein(parseInt(id),
      {
        key: this.getFormItem('key')?.value.length !== 0 ? this.getFormItem('key').value : undefined,
        value: this.getFormItem('value')?.value.length !== 0 ? this.getFormItem('value').value : '',
        locale: 'de'
      })
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'patch'));
          return EMPTY;
        })
      )
      .subscribe((data: TextbausteinDTO) => {
        this.textbaustein = {...data};
        this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'patch'));
        this.router.navigate(['/pages/textbausteine']);
      });
  }

  reloadPageWithSelectedId(){
    this.router.navigate(['/pages/textbausteine-detail/'+this.getFormItem('key').value ]);
  }

  delete(){
    if(this.route.snapshot.paramMap.get('id')!==null && this.route.snapshot.paramMap.get('id')!== ''){
      this.textbausteinService.updateTextbaustein(parseInt(this.route.snapshot.paramMap.get('id')),
        {
          key: this.getFormItem('key')?.value.length !== 0 ? this.getFormItem('key').value : undefined,
          value: '',
          locale: 'de'
        })
        .pipe(
          catchError((err) => {
            this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'patch'));
            return EMPTY;
          })
        )
        .subscribe((data: TextbausteinDTO) => {
          this.textbaustein = {...data};
          this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'delete'));
          this.router.navigate(['/pages/textbausteine']);
        });
    }
  }

  showDecisionModal(){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.compositMessageService.createDeleteWarning(this.entityNameTranslationKey);
    modalRef.componentInstance.onYes = () => {
      this.delete();
    };
  }

  showDecisionModalHTML() : Promise<boolean> {
    return new Promise((resolve) => {
      const modalRef = this.modalService.open(ConfirmComponent);
      modalRef.componentInstance.title = this.compositMessageService.textbausteinSpecialChars();
      modalRef.componentInstance.onYes = () => {
        this.isIntendedAsHTML = true;
        resolve(true);
      };
      modalRef.componentInstance.onNo = () => {
        this.isIntendedAsHTML = false;
        resolve(true);
      };
    });
  }

}

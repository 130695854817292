import {Component, OnInit} from '@angular/core';
import {AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {PasswordValidator} from "../../@core/utils/forms/password.validator";
import {accountEmailChangeValidator} from "../../@core/utils/forms/account-email-change.validator";
import {accountPwChangeValidator} from "../../@core/utils/forms/account-pw-change.validator";
import {faInfo, faKey, faEnvelope, IconDefinition, faBan, faFloppyDisk, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {AccountDTO} from "../../models/account/AccountDTO";
import {AccountService} from "../../@core/services/account/account.service";
import {BenutzerSessionService} from "../../@core/services/benutzer/benutzer-session.service";
import {catchError} from "rxjs/operators";
import {EMPTY, Subscription} from "rxjs";
import {CustomToastService} from "../../@core/utils/custom-toast.service";
import {ConfirmComponent} from "../../shared/confirm/confirm.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AuthService} from "../../@core/services/auth/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {pwFormatValidator} from "../../@core/validators/formatting/password-format.validator";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrl: './account.component.scss'
})
export class AccountComponent implements OnInit {

  pwHide = true;

  benutzerAccount: AccountDTO;

  accountForm: UntypedFormGroup;
  emailChangeForm: UntypedFormGroup;
  pwChangeForm: UntypedFormGroup;

  isActiveEmailChange: boolean = false;
  isActivePasswordChange: boolean = false;
  isActiveAccountChange: boolean = false;

  subscriptions: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private benutzerSessionService: BenutzerSessionService,
    private customToastService: CustomToastService,
    private modalService: NgbModal,
    private authService: AuthService,
    public translateService: TranslateService
  ){}

  ngOnInit() {
    this.initEmptyForms();
    this.loadAccountInfo();
  }

  loadAccountInfo() {
    this.accountService.getBenutzerAccount(this.benutzerSessionService.getCurrentBenutzerId())
      .pipe(catchError( () => {
        this.customToastService.showError(this.translateService.instant('PROFIL.ERROR.LOADING_ERROR'));
        return EMPTY;
      }))
      .subscribe( response => {
        this.benutzerAccount = response;
        this.patchAccountDtoToForms();
      });
  }

  patchAccountDtoToForms(){
    this.accountForm.patchValue({email: this.benutzerAccount?.email});
    this.emailChangeForm.patchValue({email: this.benutzerAccount?.email});
  }

  initEmptyForms(){
    this.accountForm = this.fb.group(
      {
        email: [{value: undefined, disabled: true}],
      }
    );
    this.emailChangeForm = this.fb.group(
      {
        email: [{value: undefined, disabled: true}],
        emailNeu: [undefined,[Validators.email]],
        emailNeuRepeat: [undefined,[Validators.email]]
      },
      { validators: accountEmailChangeValidator } as AbstractControlOptions
    );
    this.pwChangeForm = this.fb.group(
      {
        kennwortNeu:[undefined,[pwFormatValidator]],
        kennwortNeuRepeat:[undefined,[pwFormatValidator]],
      },
      { validator: accountPwChangeValidator } as AbstractControlOptions
    );
  }

  showConfirmationModal(){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.translateService.instant('PROFIL.MODAL.CONFIRMATION');
    modalRef.componentInstance.text = this.translateService.instant('PROFIL.MODAL.INFO_TEXT');
    modalRef.componentInstance.onNo = () => {
      this.untoggleAccountChange();
    };
    modalRef.componentInstance.onYes = () => {
      this.deployChangeRequest();
    }
    return modalRef;
  }

  deployChangeRequest(): void {
    if(this.isActiveEmailChange){
      this.accountService.requestEmailChange(this.benutzerAccount.benutzerId,{
        benutzerId: this.benutzerAccount.benutzerId,
        email: this.benutzerAccount.email,
        emailNeu: this.emailChangeForm.get('emailNeu').value,
        emailNeuRepeat: this.emailChangeForm.get('emailNeuRepeat').value
      })
        .pipe(catchError( (err) => {
          if(err.status === 409 && err.error.message.includes('email_already_exists'))
              this.customToastService.showError(this.translateService.instant('PROFIL.ERROR.EMAIL_EXISTS'));
          else
            this.customToastService.showError(this.translateService.instant('PROFIL.ERROR.VALIDATION_ERROR'));
          this.untoggleAccountChange();
          return EMPTY;
        }))
        .subscribe( response => {
          this.benutzerAccount = response;
          this.patchAccountDtoToForms();
          this.authService.logout('accountEmailChanged');
        });
    }
    else if(this.isActivePasswordChange){
      this.accountService.requestPwChange(this.benutzerAccount.benutzerId,{
        benutzerId: this.benutzerAccount.benutzerId,
        email: this.benutzerAccount.email,
        kennwortNeu: this.pwChangeForm.get('kennwortNeu').value,
        kennwortNeuRepeat: this.pwChangeForm.get('kennwortNeuRepeat').value
      })
        .pipe(catchError( (err) => {
          if(err.status === 422 && err.error.message.includes('insufficient_pw_criteria'))
            this.customToastService.showError(this.translateService.instant('PROFIL.ERROR.PASSWORD_VALIDATION_ERROR'));
          else
            this.customToastService.showError(this.translateService.instant('PROFIL.ERROR.VALIDATION_ERROR'));
          this.untoggleAccountChange();
          return EMPTY;
        }))
        .subscribe( response => {
          this.benutzerAccount = response;
          this.patchAccountDtoToForms();
          this.authService.logout('accountPWChanged');
        });
    }
  }

  toggleEmailChange(){
    this.isActiveEmailChange = true;
    this.isActivePasswordChange = false;
    this.isActiveAccountChange = true;
  }

  togglePasswordChange(){
    this.isActiveEmailChange = false;
    this.isActivePasswordChange = true;
    this.isActiveAccountChange = true;
  }

  untoggleAccountChange(){
    this.emailChangeForm.reset();
    this.pwChangeForm.reset();
    this.isActiveAccountChange = false;
    this.isActiveEmailChange = false;
    this.isActivePasswordChange = false;
  }

  ngOnDestroy(){
    this.subscriptions.forEach( it => it.unsubscribe() );
  }

  protected readonly faInfo: IconDefinition = faInfo;
  protected readonly faKey: IconDefinition = faKey;
  protected readonly faEmail: IconDefinition = faEnvelope;
  protected readonly faCancel: IconDefinition = faBan;
  protected readonly faSave: IconDefinition = faFloppyDisk;
  protected readonly faEye: IconDefinition = faEye;
  protected readonly faEyeSlash: IconDefinition = faEyeSlash;

}

import {Component, OnInit} from '@angular/core';
import {ConfirmComponent} from 'src/app/shared/confirm/confirm.component';
import {ActivatedRoute, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {SchultypDTO} from "../../../../models/schultyp/SchultypDTO";
import {catchError} from "rxjs/operators";
import {SchultypService} from "../../../../@core/services/schultyp/schultyp.service";
import {CompositMessageService} from "../../../../@core/services/compositMessage/compositMessage.service";
import {TranslateService} from "@ngx-translate/core";
import {EMPTY} from "rxjs";
import {CustomToastService} from "../../../../@core/utils/custom-toast.service";
import {faSave, faTrash} from "@fortawesome/free-solid-svg-icons";

/**
 * deprecated since 2.1.7
 * ASM standalone ("hasSachbearbeiterbackend=false") is no longer supported
 */
@Component({
  selector: 'app-schultypen-detail',
  templateUrl: './schultypen-detail.component.html',
  styleUrls: ['./schultypen-detail.component.css']
})
export class SchultypenDetailComponent implements OnInit {

  faSave = faSave;
  faDelete = faTrash;

  schultyp: SchultypDTO;
  schultypenList: Array<SchultypDTO>
  public schultypForm: UntypedFormGroup;

  entityNameTranslationKey : string = 'SCHULTYP';

  constructor(

    private route: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private schultypService: SchultypService,
    private fb: UntypedFormBuilder,
    private customToastService: CustomToastService,
    private modalService: NgbModal,
    private compositMessageService: CompositMessageService
  ) { }

  ngOnInit(): void {

    this.schultypService.getSchultypen().subscribe(schultypen => this.schultypenList = schultypen);
    this.schultypForm = this.fb.group({
      name: ['', [Validators.required]],
      archiv: [false, [Validators.required]],
      ablehnungsgrenze: ['',],
      bestaetigungsgrenze: ['',]
    });
    const schultypId = this.route.snapshot.paramMap.get('id');
    if (this.route.snapshot.paramMap.get('id') && this.route.snapshot.paramMap.get('id') !== '' &&
      this.route.snapshot.paramMap.get('id') !== null) {
      this.schultypService.getSchultyp(schultypId).subscribe((data: SchultypDTO) =>
      {
        this.schultyp = {...data};
        this.schultypForm.patchValue({
          name: this.schultyp.name
        });
      });
    }

  }

  createSchultyp() {
    this.schultypService.createSchultyp(
      {
        name: this.getFormItem('name')?.value.length !== 0 ? this.getFormItem('name').value : null
      })
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'post'));
          return EMPTY;
        })
      )
      .subscribe((data: SchultypDTO) => {
        this.schultyp = {...data};
        this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'post'));
        this.router.navigate(['/pages/schultypen']);
      });
  }

  updateSchultyp() {
    this.schultypService.updateSchultyp(parseInt(this.route.snapshot.paramMap.get('id')),
      {
        name: this.getFormItem('name')?.value.length !== 0 ? this.getFormItem('name').value : undefined
      })
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'patch'));
          return EMPTY;
        })
      )
      .subscribe((data: SchultypDTO) => {
        this.schultyp = {...data};
        this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'patch'));
        this.router.navigate(['/pages/schultypen']);
      });
  }

  saveOrUpdate() {
    if(!this.schultypForm.valid){
      this.customToastService.showError(this.compositMessageService.createIncompleteMessage(this.entityNameTranslationKey));
      return;
    }
    if (this.route.snapshot.paramMap.get('id') !== null && this.route.snapshot.paramMap.get('id') !== '') {
      this.updateSchultyp();
    } else {
      this.createSchultyp();
    }
  }

  deleteSchultyp() {
    this.schultypService.deleteSchultyp(parseInt(this.route.snapshot.paramMap.get('id')))
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'delete'));
          return EMPTY;
        })
      )
      .subscribe(
        () => {
          this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'delete'));
          this.router.navigate(['/pages/schultypen']);
        },
      );
  }

  getFormItem(s: string) {
    return this.schultypForm?.get(s);
  }

  showDecisionModal(){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.compositMessageService.createDeleteWarning(this.entityNameTranslationKey);
    modalRef.componentInstance.onYes = () => {
      this.deleteSchultyp();
    };
  }

}

<div id="countdown" data-cy="countdown" >
  <div class="timer" *ngIf="(timeLeft$ | async) as t" data-cy="timer"> <!---->
    <div id="timeUntillLogout">
      <span class="countdowntimer" *ngIf="t.daysToDday > 0" id="days">{{t.daysToDday}}</span>
      <span class="countdowntimer2" *ngIf="t.daysToDday > 0" id="days2">:</span>
      <span class="countdowntimer" *ngIf="t.hoursToDday > 0 " id="hours">{{t.hoursToDday.toString().padStart(2, '0')}}</span>
      <span class="countdowntimer2" *ngIf="t.hoursToDday > 0 " id="hours2">:</span>
      <span class="countdowntimer" *ngIf="t.minutesToDday >= 0" id="minutes">{{t.minutesToDday.toString().padStart(2, '0')}}</span>
      <span class="countdowntimer2" *ngIf="t.minutesToDday >= 0" id="minutes2">:</span>
      <span class="countdowntimer" *ngIf="t.secondsToDday >= 0" id="seconds">{{t.secondsToDday.toString().padStart(2, '0')}} </span>
      <span class="countdowntimer2" *ngIf="t.secondsToDday >= 0" id="seconds2"></span>
    </div>
    <ng-template *ngIf="this.loggedoutMessageVisible" >
    <span *ngIf="t.daysToDday <= 0 && t.hoursToDday <=0 && t.minutesToDday <= 0 && t.secondsToDday <= 0" >Sie wurden ausgeloggt. <br/>
      Bitte wieder einloggen.
      </span>
    </ng-template>
  </div>
</div>

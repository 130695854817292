import {Component, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {BenutzerDTO} from "./models/benutzer/BenutzerDTO";
import {Router} from "@angular/router";
import {FieldConfigService} from "./@core/services/field-config/field-config.service";
import {environment} from "../environments/environment";
import {TextbausteinService} from "./@core/services/textbaustein/textbaustein.service";
import {HttpParams} from "@angular/common/http";
import {TextbausteinDTO} from "./models/textbaustein/TextbausteinDTO";
import {ModuleConfigService} from "./@core/services/module-config/module-config.service";
import {DomSanitizer} from "@angular/platform-browser";
import {SessionService} from "./@core/services/session/session.service";
import {AuthService} from "./@core/services/auth/auth.service";
import {ModuleConfigDto} from "./models/module-config/ModuleConfigDto";
import {ConfigService} from "./@core/config/services/config.service";
import {BenutzerSessionService} from "./@core/services/benutzer/benutzer-session.service";
import {BehaviorSubject, of} from "rxjs";
import {CountdownComponent} from "./shared/countdown/countdown/countdown.component";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  @ViewChild(CountdownComponent) countdownComponent: CountdownComponent;

  title = 'SFK-Antragsteller-Frontend';
  version = environment.version;
  logoSource = "../assets/images/logo.png";
  safeLogoSource = this.sanitize(this.logoSource);

  benutzer : BenutzerDTO;
  currentRoute: string;
  moduleEnabled = [];
  roleEnabled = [];
  existsSessionUser: boolean = false;

  loading = false;

  public millisecUntilLogout$: BehaviorSubject<number>;
  public endDateForLogout$: BehaviorSubject<Date>;
  public loggedoutMessageVisible$ : BehaviorSubject<boolean>;
  private tokenLastRefreshed: Date = new Date();
  private tokenRefreshIntervalMilis = 60000; // 1 minute

  constructor(
    public translate: TranslateService,
    public router: Router,
    private fieldConfigService: FieldConfigService,
    private moduleConfigService: ModuleConfigService,
    public textbausteinService: TextbausteinService,
    private sanitizer: DomSanitizer,
    private sessionService: SessionService,
    private authService: AuthService,
    public configService: ConfigService,
    private benutzerSessionService: BenutzerSessionService
  ) {
    this.millisecUntilLogout$ = new BehaviorSubject<number>(0);
    this.endDateForLogout$ = new BehaviorSubject<Date>(new Date());
    this.loggedoutMessageVisible$ = new BehaviorSubject<boolean>(false);
  }

  ngOnInit(): void {

    this.loading = true;
    this.currentRoute = this.router.url;

    this.benutzerSessionService.watchSessionStorageBenutzer().subscribe( user => this.benutzer = user );
    this.benutzerSessionService.watchSessionStorageExistsBenutzer().subscribe( yesNo => {
      this.existsSessionUser = yesNo;
      this.applyBenutzerRoles(this.benutzer?.roles);
      if(yesNo === false) {
        this.loggedoutMessageVisible$.next(true);
      }
    });

    this.benutzerSessionService.watchSessionStorageTimeToLive().subscribe( time => {
      this.millisecUntilLogout$.next(time);
    });

    this.benutzerSessionService.watchSessionTimestamp().subscribe( timestamp => {
      let endTimestampValue = new Date(timestamp?.getTime() + this.millisecUntilLogout$?.value);
      this.endDateForLogout$?.next(endTimestampValue);
    });


    // Load textbausteine
    this.textbausteinService.getTextbausteinList(new HttpParams().set('limit', '10000').set('orderbycolumn','key'))
      .subscribe( (dtoList: TextbausteinDTO[]) => {
        sessionStorage.setItem('textbausteine', JSON.stringify(dtoList));
        this.sessionService.broadcastTextbausteineChange();
      });

    // Load Field Config
    this.fieldConfigService.getFieldConfigs().subscribe(result => {
      sessionStorage.setItem('field-config', JSON.stringify(result));
    });

    // Load Module Config and put into storage
    this.moduleConfigService.getModuleConfigList().subscribe((dtoList: ModuleConfigDto[]) => {
      sessionStorage.setItem('module-config', JSON.stringify(dtoList));
      this.applyModuleConfig(dtoList);
      this.sessionService.broadcastModuleConfigChange();
    });

    this.logoSource = this.configService?.logo?.data || this.logoSource;
    this.safeLogoSource = this.sanitize(this.logoSource);
    this.loading = false;

  }

  sanitize(url: string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  protected readonly environment = environment;

  applyModuleConfig(configs: ModuleConfigDto[]){
    this.moduleEnabled = [];
    configs.forEach( mc => {
      this.moduleEnabled[mc.module] = mc.enabled;
    });
  }

  applyBenutzerRoles(roles: string[]){
    this.roleEnabled = [];
    let temp = ['Antragsteller','Schule','Schultraeger','Superuser'];
    temp.forEach( role => {
      this.roleEnabled[role] = roles?.includes('ROLE_'+role.toUpperCase()) || false;
    });
  }

  protected readonly of = of;
}



import {Component, inject} from '@angular/core';
import {TextbausteinService} from "../../@core/services/textbaustein/textbaustein.service";
import {SessionService} from "../../@core/services/session/session.service";
import {KeyString} from "../../models/textbaustein/KeyString";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.scss']
})
export class DatenschutzComponent {

  existsSessionStorageTextbausteine: boolean = false;
  activeModal?: NgbActiveModal = inject(NgbActiveModal, {optional: true});

  constructor(
    public textbausteinService: TextbausteinService,
    private sessionService: SessionService,
  ) { }

  ngOnInit(): void {
    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => this.existsSessionStorageTextbausteine = yesNo );
  }

  closeModal() {
    this.activeModal.dismiss();
  }

  defaultText = "<div class=\"WordSection2\">" +
    "  <p><b>Verantwortlich für die Datenerhebung ist:</b></p>" +
    "  <p>Stadt Muster</p>" +
    "  <p>Der Landrat</p>" +
    "  <p>Musterstraße 1</p>" +
    "  <p>12345 Muster</p>" +
    "  <p>Telefon: &nbsp;&nbsp;&nbsp; 01234 123-0</p>" +
    "  <p>Fax:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 01234 123-1234</p>" +
    "  <p>E-Mail:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href=\"mailto:verwaltung@stadt-muster.de\">verwaltung@stadt-muster.de</a></p>" +
    "  <p><b>Zwecke der Datenverarbeitung</b></p>" +
    "  <p>Beantragung und Erstattung Schülertickets auf digitalen Weg </p>" +
    "  <p><b>Rechtsgrundlagen der Datenverarbeitung</b></p>" +
    "  <p>Einwilligung nach § 6 Abs. 1 Satz 1 lit a DSGVO i. V. m. § 3 Abs. 1 DSG NRW</p>" +
    "  <p><b>Empfänger der Daten</b></p>" +
    "  <p>Schulamt der Stadt Muster (Amt 40 – Abteilung XY, Schulen, Regio IT)</p>" +
    "  <p><b>Dauer der Datenspeicherung</b></p>" +
    "  <p>18 Monate </p>" +
    "  <p><b>Ihre Rechte nach Art. 15-20, Art. 77 Datenschutz-Grundverordnung:</b></p>" +
    "  <p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Auskunft </b>über die erhobenen Daten</p>" +
    "  <p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Berichtigung </b>unrichtiger oder unrichtig gewordener Daten</p>" +
    "  <p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Löschung</b></p>" +
    "  <p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Einschränkung</b> der Verarbeitung</p>" +
    "  <p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Datenübertragbarkeit</b></p>" +
    "  <p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Widerspruch</b> gegen die Verarbeitung</p>" +
    "  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>-</b> Jederzeitiger <b>Widerruf der Einwilligung</b> mit Wirkung für die Zukunft, sofern eine Einwilligung erteilt wurde</p>" +
    "  <p><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Beschwerde </b>bei der Aufsichtsbehörde: </p>" +
    "  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Landesbeauftragte für Datenschutz und Informationsfreiheit (LDI NRW)</p>" +
    "  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Postfach 20 04 44</p>" +
    "  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;40102 Düsseldorf</p>" +
    "  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Telefon:&nbsp;&nbsp;&nbsp;&nbsp; 0211 38424-0</p>" +
    "  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Telefax: &nbsp;&nbsp;&nbsp;0211 38424-10</p>" +
    "  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E-Mail: &nbsp;&nbsp;&nbsp;&nbsp; <a href=\"mailto:poststelle@ldi.nrw.de\">poststelle@ldi.nrw.de</a></p>" +
    "  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Internet: &nbsp;&nbsp; <a href=\"http://www.ldi.nrw.de\">www.ldi.nrw.de</a></p>" +
    "  <p><b>Kontaktdaten des behördlichen Datenschutzbeauftragten</b> <b>der Stadt Muster</b></p>" +
    "  <p>E-Mail: <a href=\"mailto:datenschutz@stadt-muster.de\"><span lang=\"EN-US\">datenschutz@stadt-muster.de</span></a><span lang=\"EN-US\">; Tel. 01234 123-1234, Fax: -12 1234</span></p>" +
    "  <div style=\"border:solid windowtext 1.0pt;padding:1.0pt 4.0pt 1.0pt 21.0pt;margin-left:17.85pt;margin-right:0cm\">" +
    "    <p><span lang=\"EN-US\">Bei personenbezogenen Anfragen ist aus Gründen der Datensicherheit eineVerschlüsselung von E-Mails oder die Nutzung von De-Mail zu empfehlen. In derKommunikation zwischen Behörden gibt es die Möglichkeit zur Nutzung dessicheren DOI-Netzes. Zur zweifelsfreien Identifizierung ist eine elektronischeSignatur oder die Nutzung von De-Mail ratsam. Informationen zur rechtssicherenKommunikation mit der Stadt Muster finden Sie unter:</span></p>" +
    "    <p><a href=\"https://www.stadt-muster.de/\"><span lang=\"EN-US\">https://www.stadt-muster.de/</span></a></p>" +
    "  </div>" +
    "</div>";

    protected readonly KeyString = KeyString;
}

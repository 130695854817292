import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {EMPTY, Observable, of} from "rxjs";
import {SchultypDTO} from "../../../models/schultyp/SchultypDTO";
import {catchError} from "rxjs/operators";
import {SchultypService} from "../../../@core/services/schultyp/schultyp.service";
import {CompositMessageService} from "../../../@core/services/compositMessage/compositMessage.service";
import {TranslateService} from "@ngx-translate/core";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {faPlus, faSort} from '@fortawesome/free-solid-svg-icons';
import {nullLastComparator} from "../../../@core/utils/forms/null-last-comparator";

/**
 * deprecated since 2.1.7
 * ASM standalone ("hasSachbearbeiterbackend=false") is no longer supported
 */
@Component({
  selector: 'app-schultypen',
  templateUrl: './schultypen.component.html',
  styleUrls: ['./schultypen.component.css']
})
export class SchultypenComponent implements OnInit {

  @Input() rows$: Observable<SchultypDTO[]> = of([]);

  public columns: any;
  public rows: any;

  faPlus = faPlus;
  sortInitial = faSort;

  isLoading: boolean = false;
  dataFetched: boolean = false;

  entityNameTranslationKey: string = 'SCHULTYP';

  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private schultypService: SchultypService,
    private customToastService: CustomToastService,
    private compositMessageService: CompositMessageService
  ) { }

  ngOnInit(): void {

    this.columns = [
      { name: 'name', headerTemplate: this.hdrTpl, comparator: nullLastComparator.bind(this) }
    ];

    this.reloadTable();

  }

  onActivate(event) {
    if(event.type == 'click') {
      this.router.navigate(['/pages/schultypen-detail/'+event.row.id ]).catch(()=>{});
    }
  }

  reloadTable() {
    this.isLoading = true;
    this.dataFetched = false;
    this.schultypService.getSchultypen()
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'getBulk'));
          this.isLoading = false;
          this.dataFetched = null;
          return EMPTY;
        })
      )
      .subscribe((data) => {
        this.rows = data;
        this.isLoading = false;
        this.dataFetched = this.rows.length>0;
      });
  }

}

<app-default-layout [title]="'Passwort zurücksetzen'">
  <div class="row">
    <div class="col-md-8 offset-md-2">
      <form [formGroup]="pwZuruecksetzenForm" (ngSubmit)="this.save()" class="form-horizontal"
            accept-charset="UTF-8">

        <div
          class="flex-grow-1 mr-2 mb-3"
        >
          Geben Sie die E-Mail-Adresse ein, für die Ihr Benutzerkonto registriert ist.
          Nach Absenden erhalten Sie eine E-Mail mit einem Link zum Zurücksetzen des Passworts.
        </div>

        <div class="form-group group-for-email col mb-3">
          <div class="d-flex flex-row">
            <div class="flex-grow-1 mr-2">
              <app-custom-input id="email" type="text"
                                class="flex-grow-1"
                                label="{{'REGISTRIERUNG.MAIL' |translate }}"
                                [customFormGroup]="pwZuruecksetzenForm"
              ></app-custom-input>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column">
          <button type="submit" class="btn btn-primary btn-block">Absenden</button>
        </div>
      </form>
    </div>
  </div>
</app-default-layout>

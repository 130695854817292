<app-default-layout
  [title]="existsSessionStorageTextbausteine?
    textbausteinService.printTextbausteinByKey( KeyString.TITLE_HOME, ('HOME.TITLE'|translate) ):('HOME.TITLE'|translate)"
  [subtitle]="existsSessionStorageTextbausteine?
    textbausteinService.printTextbausteinByKey( KeyString.SUBTITLE_HOME, ('HOME.SUBTITLE'|translate) ):('HOME.SUBTITLE'|translate)"
>

  <main role="main" class="container-fluid" aria-label="">
    <div class="row justify-content-center">
      <main class="col-7">
        <div class="frame frame-full">
          <div id="welcome-page" class="content scaffold-edit justify-content-center" role="main" aria-label="">
            <div class="d-flex flex-row">
              <div class="flex-grow-1 texthome mb-1">
                {{existsSessionStorageTextbausteine?
                  textbausteinService.printTextbausteinByKey( KeyString.WELCOME_TEXT_HOME, ('HOME.WELCOME_TEXT'|translate) ):('HOME.WELCOME_TEXT'|translate)}}
              </div>
            </div>
            <div *ngIf="existsSessionUser && roleEnabled['Antragsteller']">
                <a target="_blank" href="https://www.schulministerium.nrw/fragen-und-antworten-zu-schuelerfahrkosten-und-schuelerticket" data-cy="info">
                  {{ "HOME.LINK_GENERAL_INFO" | translate }}
                </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </main>

</app-default-layout>

import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Router} from "@angular/router";
import {FieldConfigService} from "../../../@core/services/field-config/field-config.service";
import {FieldConfigCreateOrUpdateDTO} from "../../../models/field-config/FieldConfigCreateOrUpdateDTO";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {ModuleConfigService} from "../../../@core/services/module-config/module-config.service";
import {ModuleConfigCreateOrUpdateDto} from "../../../models/module-config/ModuleConfigCreateOrUpdateDto";
import {ModuleConfigDto} from "../../../models/module-config/ModuleConfigDto";
import {TranslateService} from "@ngx-translate/core";

/**
 * deprecated since 2.1.7
 * ASM standalone ("hasSachbearbeiterbackend=false") is no longer supported
 */
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {
  benutzer: BenutzerDTO = JSON.parse(sessionStorage.getItem('user')) as BenutzerDTO;

  antragTicketForm: UntypedFormGroup;
  antragTicketSepaForm: UntypedFormGroup;
  fahrkostenForm: UntypedFormGroup;
  fahrkostenKostenUniversalForm: UntypedFormGroup;
  benutzerForm: UntypedFormGroup;
  schuelerForm: UntypedFormGroup;
  visibleConfig = "";

  modules: {
    name: string;
    fields: string[];
    module: string;
    formGroup: UntypedFormGroup;
    selectLabel: string;
  }[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private fieldConfigService: FieldConfigService,
    protected moduleConfigService: ModuleConfigService,
    private customToastService: CustomToastService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.antragTicketForm = this.fb.group({
      name: [true],
      vorname: [true],
      strasse: [true],
      plz: [true],
      ort: [true],
      geburtsdatum: [true],
      email: [true],
      emailSchueler: [true],
      telefonnummer: [true],
      land: [true],
      einstieghaltestelle: [true],
      ausstieghaltestelleHinweg: [true],
      typ: [true],
      wegtyp: [true],
      //schule: [true],   // hardcoded required
      //isBerufskolleg: [true], // always shows up when schule = berufskolleg  // hardcoded as neverValidate
      schuljahr: [true],
      klasse: [true],
      bildungsgang: [true],
      bildungsgangdatum: [true],
      fachbereich: [true],
      ticket: [true],
      aboGueltigkeit: [true],
      geschlecht: [true],
      referenzFuerZahlungen: [true], // hardcoded as neverValidate
      abonnementnummerAntrag: [true],
      einverstaendnisErklaerung: [true], // hardcoded as neverValidate
      marketingfreigabe: [true],
      geschwisterkind1: [true], // hardcoded as neverValidate
      geschwisterkind2: [true], // hardcoded as neverValidate
      geschwisterkind3: [true], // hardcoded as neverValidate
      geschwisterkind4: [true], // hardcoded as neverValidate
      geschwisterkind5: [true], // hardcoded as neverValidate
      selbstzahlerExport: [true],
      fahrkostenberechtigt: [true],
      umgezogen: [true],
      alteTicketsLiegenBei: [true],
      alteTicketsWerdenZurueckgesandt: [true],
      eigenanteil: [true],
      zuzahlung: [true],
      vormundName: [true],
      vormundVorname: [true],
      isVormundadresseAbweichend: [true], // hardcoded as neverValidate
      vormundStrasse: [true],
      vormundOrt: [true],
      vormundPlz: [true],
      vormundAdresszusatz: [true], // hardcoded as neverValidate
      vormundLand: [true],
      vormundGeburtsdatum: [true],
      vormundTelefonnummer: [true],
      vormundEmail: [true],
      vormundGeschlecht: [true],
      schultageJeWoche: [true],
      praktikumHinweis: [true], // hardcoded as neverValidate
      praktikumStelle: [true],
      praktikumStrasse: [true],
      praktikumOrt: [true],
      praktikumPlz: [true],
      praktikumLand: [true],
      praktikumAdresszusatz: [true], // hardcoded as neverValidate
      praktikumStartdatum: [true],
      praktikumEnddatum: [true],
      praktikumstageJeWoche: [true],
      sonderfallEnabled: [true], // hardcoded as neverValidate
      sonderfall: [true], // hardcoded as neverValidate
      sonderfallBegruendung: [true],
      bezugAsylbewerberleistungsgesetz: [true], // hardcoded as neverValidate
      adresszusatz: [true], // hardcoded as neverValidate
      praktikumsvertrag: [true],
      sonderfallNachweis: [true],
      bezugNachweis: [true],
      meldedatenabgleichAutomatisch:[true], // hardcoded as neverValidate
      meldedatenabgleichManuell:[true],
      richtigkeitDerAngaben:[true]
    });

    Object.keys(this.antragTicketForm.controls).forEach((key: string) => {
      this.antragTicketForm.addControl(key+'Required', this.fb.control(true));
      this.antragTicketForm.addControl(key+'Label', this.fb.control(''));
    });

    this.antragTicketSepaForm = this.fb.group({
      vornameSepa: [true],
      nameSepa: [true],
      strasseSepa: [true],
      plzSepa: [true],
      ortSepa: [true],
      adresszusatzSepa: [true], // hardcoded as neverValidate
      landSepa: [true],
      emailSepa: [true],
      telefonnummerSepa: [true],
      geburtsdatumSepa: [true],
      geschlechtSepa: [true],
      iban: [true],
      ibanSepa: [true],
      isAntragstellerNotMandatsgeber: [true], // hardcoded as neverValidate
      datenschutz: [true],
      bicSepa: [true],
      bic: [true],
      nameKreditinstitut: [true],
      nameKreditinstitutSepa: [true]
    });

    Object.keys(this.antragTicketSepaForm.controls).forEach((key: string) => {
      this.antragTicketSepaForm.addControl(key+'Required', this.fb.control(true));
      this.antragTicketSepaForm.addControl(key+'Label', this.fb.control(''));
    });

    this.benutzerForm = this.fb.group({
      name: [true],
      vorname: [true],
      strasse:[true],
      plz:[true],
      ort:[true],
      geburtsdatum:[true],
      geschlecht:[true],
      telefonnummer: [true],
      //iprestriction: [true],
      email:[true],
      emailNeu:[true],
      schuleId:[true],
      land: [true],
      abonnementnummer: [true],

      //Konto
      iban: [true],
      bic: [true],
      kontoinhaber: [true],
      schuelernummer: [true],
      aktuelleRolle: [true],
      lastFailure: [true],
      lastSuccess: [true],
      routenDarstellung: [true]
    });

    Object.keys(this.benutzerForm.controls).forEach((key: string) => {
      this.benutzerForm.addControl(key+'Required', this.fb.control(true));
      this.benutzerForm.addControl(key+'Label', this.fb.control(''));
    });

    this.schuelerForm = this.fb.group({
      name: [true],
      vorname: [true],
      strasse:[true],
      plz:[true],
      ort:[true],
      geburtsdatum:[true],
      adresszusatz:[true],
      land:[true],
      geschlecht:[true],
      schule:[true],
      schuelernummer:[true],
      abonnementnummer:[true],

    });

    Object.keys(this.schuelerForm.controls).forEach((key: string) => {
      this.schuelerForm.addControl(key+'Required', this.fb.control(true));
      this.schuelerForm.addControl(key+'Label', this.fb.control(''));
    });

    this.fahrkostenForm = this.fb.group({
      name: [false],
      vorname: [true],
      strasse:[true],
      plz:[true],
      ort:[true],
      land: [true],
      adresszusatz: [true], // hardcoded as neverValidate
      geburtsdatum:[true],
      geschlecht:[true],
      telefonnummer: [true],
      email:[true],
      emailSchueler: [true],
      // schule:[true],    // hardcoded required
      // isBerufskolleg: [true], // always visible when schule=berufskolleg  // hardcoded as neverValidate
      schuljahr:[true],
      klasse:[true],
      unterrichtszeitVon:[true],
      unterrichtszeitBis:[true],
      praktikumStelle: [true],
      erstattungszeitraumVon: [true],
      erstattungszeitraumBis: [true],
      fehltage: [true],
      krankheitstage: [true],
      //kosten: [true],  // hardcoded required (for calculations in SB module)
      befoerderung:[true],
      kennzeichen:[true],
      mitfahrerBei:[true],  // hardcoded as neverValidate
      //entfernung:[true],  // hardcoded required (for calculations in SB module)
      //hinweiseAntragsteller:[true],
      bemerkungSchule:[true],
      bemerkungSchultraeger:[true],
      meldedatenabgleichAutomatisch:[true], // hardcoded as neverValidate
      meldedatenabgleichManuell:[true],
      entfernungKorrektur:[true],
      eigenanteilP2Abs2:[true],
      eigenanteilP2Abs3:[true],
      verbleibendeKosten:[true],
      gesamtErstattung:[true],
      nichtErstattet:[true],
      buchungsdatumDerErstattung:[true],
      erstattungFuerBusfahrten:[true],
      erstattungFuerBahnfahrten:[true],
      erstattungFuerFahrradNutzung:[true],
      erstattungFuerTaxifahrten:[true],
      erstattungFuerpkwnutzung:[true],
      erstattungFuerkfznutzung:[true],
      textbausteinId:[true], // hardcoded as neverValidate
      nachrichtAnAntragsteller:[true],
      vormundName: [true],
      vormundVorname: [true],
      isVormundadresseAbweichend:[true], // hardcoded as neverValidate
      vormundStrasse: [true],
      vormundOrt: [true],
      vormundPlz: [true],
      vormundAdresszusatz: [true], // hardcoded as neverValidate
      vormundLand: [true],
      vormundGeburtsdatum: [true],
      vormundTelefonnummer: [true],
      vormundEmail: [true],
      vormundGeschlecht: [true],

      typ: [true],
      wegtyp: [true],
      bildungsgang: [true],
      bildungsgangdatum:[true],
      fachbereich:[true],
      schultageJeWoche: [true],

      praktikumHinweis: [true], // hardcoded as neverValidate
      praktikumStrasse: [true],
      praktikumOrt: [true],
      praktikumPlz: [true],
      praktikumLand: [true],
      praktikumAdresszusatz: [true], // hardcoded as neverValidate
      praktikumStartdatum: [true],
      praktikumEnddatum: [true],
      praktikumstageJeWoche: [true],
      praktikumsvertrag: [true],

      sonderfallEnabled: [false], // hardcoded as neverValidate
      sonderfall: [true], // hardcoded as neverValidate
      sonderfallBegruendung: [true],
      sonderfallSchuleAusserhalb: [true], // hardcoded as neverValidate
      sonderfallSchulungsmoeglichkeit: [true], // hardcoded as neverValidate
      sonderfallFamilienheimfahrten: [true], // hardcoded as neverValidate
      sonderfallNachweis: [true],

      bezugAsylbewerberleistungsgesetz: [true], // hardcoded as neverValidate
      bezugNachweis: [true],

      fahrkostenNachweis: [true],

      //KOnto
      iban: [true],
      bic: [true],
      isKontoinhaberAbweichend: [true], // hardcoded as neverValidate
      kontoinhaberName: [true],
      kontoinhaberVorname: [true],
      kontoinhaberStrasse: [true],
      kontoinhaberOrt: [true],
      kontoinhaberPlz: [true],
      kontoinhaberAdresszusatz: [true], // hardcoded as neverValidate
      kontoinhaberLand: [true],
      kontoinhaberGeburtsdatum: [true],

      acceptTerms: [true]

    });

    Object.keys(this.fahrkostenForm.controls).forEach((key: string) => {
      this.fahrkostenForm.addControl(key+'Required', this.fb.control(true));
      this.fahrkostenForm.addControl(key+'Label', this.fb.control(''));
    });

    this.fahrkostenKostenUniversalForm = this.fb.group({
      //gefahreneTageAuswahl: true,   // hardcoded required (for calculations in SB module)
      belegArt: true,
      //einzelpreis: true,  // hardcoded required (for calculations in SB module)
      //gesamtpreis: true,  // irrelevant in AS module
      //fahrzeugtyp: true,  // hardcoded required (for calculations in SB module)
      kennzeichen: true,
      gruende: true,
      mitfahrerBei: true, // hardcoded as neverValidate
      mitgenommen: true,  // hardcoded as neverValidate
      belegupload: true,
      hasSchuelermitnahme: true, // hardcoded as neverValidate
      mitfahrer1: true, // hardcoded as neverValidate
      mitfahrer2: true, // hardcoded as neverValidate
      mitfahrer3: true, // hardcoded as neverValidate
      mitfahrer4: true, // hardcoded as neverValidate
      mitfahrer5: true, // hardcoded as neverValidate
      erstattungFuerPkwnutzung: true,  // irrelevant in AS module (SBfalse may is missing fahrzeugtyp data needed for calc)
      //gefahreneTageConfirmed: true, // irrelevant in AS module
    });

    Object.keys(this.fahrkostenKostenUniversalForm.controls).forEach((key: string) => {
      this.fahrkostenKostenUniversalForm.addControl(key+'Required', this.fb.control(true));
      this.fahrkostenKostenUniversalForm.addControl(key+'Label', this.fb.control(''));
    });


    // initialize modules object to get all the fields we need inside .html file.
    this.modules = [
      {
        name: 'Ticketanträge',
        fields: this.getFieldsFromFormGroup(this.antragTicketForm).filter(field => !field.includes('Required') && !field.includes('Label')),
        module: 'antragTicket',
        formGroup: this.antragTicketForm,
        selectLabel: 'Ticketanträge ('
          + (this.moduleConfigService.isModuleEnabled('antragTicket') ? 'aktiviert' : 'deaktiviert')
          + ')'
      },
      {
        name: 'Sepa Ticketanträge',
        fields: this.getFieldsFromFormGroup(this.antragTicketSepaForm).filter(field => !field.includes('Required') && !field.includes('Label')),
        module: 'antragTicketSepa',
        formGroup: this.antragTicketSepaForm,
        selectLabel: 'Sepa Ticketanträge ('
          + (this.moduleConfigService.isModuleEnabled('antragTicketSepa') ? 'aktiviert' : 'deaktiviert')
          + ')'
      },
      {
        name: 'Fahrkostenanträge',
        fields: this.getFieldsFromFormGroup(this.fahrkostenForm).filter(field => !field.includes('Required') && !field.includes('Label')),
        module: 'fahrkosten',
        formGroup: this.fahrkostenForm,
        selectLabel: 'Fahrkostenanträge ('
          + (this.moduleConfigService.isModuleEnabled('fahrkosten') ? 'aktiviert' : 'deaktiviert')
          + ')'
      },
      {
        name: 'Fahrkostennachweise',
        fields: this.getFieldsFromFormGroup(this.fahrkostenKostenUniversalForm).filter(field => !field.includes('Required') && !field.includes('Label')),
        module: 'fahrkostenKostenUniversal',
        formGroup: this.fahrkostenKostenUniversalForm,
        selectLabel: 'Fahrkostennachweise ('
          + (this.moduleConfigService.isModuleEnabled('fahrkostenKostenUniversal') ? 'aktiviert' : 'deaktiviert')
          + ')'
      },
      {
        name: 'Schüler',
        fields: this.getFieldsFromFormGroup(this.schuelerForm).filter(field => !field.includes('Required') && !field.includes('Label')),
        module: 'schueler',
        formGroup: this.schuelerForm,
        selectLabel: 'Schüler ('
          + (this.moduleConfigService.isModuleEnabled('schueler') ? 'aktiviert' : 'deaktiviert')
          + ')'
      },
      {
        name: 'Routing',
        fields: [],
        module: 'routing',
        formGroup: null,
        selectLabel: 'Routing ('
          + (this.moduleConfigService.isModuleEnabled('routing') ? 'aktiviert' : 'deaktiviert')
          + ')'
      }
    ];

    // get field config from Backend and patch forms
    this.fieldConfigService.getFieldConfigs().subscribe(data => {
      this.patchFormValues(this.antragTicketForm, data, 'antragTicket');
      this.patchFormValues(this.antragTicketSepaForm, data, 'antragTicketSepa');
      this.patchFormValues(this.fahrkostenForm, data, 'fahrkosten');
      this.patchFormValues(this.benutzerForm, data, 'benutzer');
      this.patchFormValues(this.schuelerForm, data, 'schueler');
      this.patchFormValues(this.fahrkostenKostenUniversalForm, data,'fahrkostenKostenUniversal');
    });
  }

  patchFormValues(form: UntypedFormGroup, dataList: any, module: string) {
    const filteredDataList = dataList.filter(dto => dto.module === module);
    for (let data of filteredDataList) {
      this.getFormItem(form, data.field)?.patchValue(data.visible);
      this.getFormItem(form, data.field+'Required')?.patchValue(data.required);
      this.getFormItem(form, data.field+'Label')?.patchValue(data.label);
    }
  }

  createOrUpdateFieldConfigs(
    module: {
      name: string;
      fields: string[];
      module: string;
      formGroup: UntypedFormGroup;
      selectLabel: string;
    }
  ) {
    let dtoList: FieldConfigCreateOrUpdateDTO[] = [];
    // create dtos
    Object.keys(module.formGroup.controls).forEach((key: string) => {
      const visible = this.getFormItem(module.formGroup, key)?.value;
      const required = this.getFormItem(module.formGroup, key+'Required')?.value;
      const label = this.getFormItem(module.formGroup, key+'Label')?.value;
      if (!key.includes('Required') && !key.includes('Label')) {
        dtoList.push({field: key, visible: visible, required: required, label: label});
      }
    });

    // send request to backend
    this.fieldConfigService.createOrUpdateFieldConfigs(dtoList, module.module).subscribe(data => {
      sessionStorage.setItem('field-config', JSON.stringify(data));
      this.customToastService.showSuccess(this.translateService.instant('CONFIG.SPEICHERN_ERFOLGREICH'));
    });
  }

  getFieldsFromFormGroup(formGroup: UntypedFormGroup) {
    return Object.keys(formGroup.controls);
  }

  getFormItem(form: UntypedFormGroup, field: string) {
    return form?.get(field);
  }

  toggleAllFormControlValues(event: any, formGroup: UntypedFormGroup, field: string) {
    Object.keys(formGroup.controls).forEach((key: string) => {
      if (field === 'visible' && !key.includes('Required') && !key.includes('Label')) {
        this.getFormItem(formGroup, key)?.patchValue(event.target.checked);
      } else if (field === 'required' && key.includes('Required')) {
        this.getFormItem(formGroup, key)?.patchValue(event.target.checked);
      }
    });
  }

  createOrUpdateModuleConfig(
    module: {
      name: string;
      fields: string[];
      module: string;
      formGroup: UntypedFormGroup;
      selectLabel: string;
    }
  ) {
    const enabled = !this.moduleConfigService.isModuleEnabled(module.module);
    const dto: ModuleConfigCreateOrUpdateDto = {
      module: module.module,
      enabled: enabled
    };
    this.moduleConfigService.createOrUpdateModuleConfig(dto).subscribe((dtoList: ModuleConfigDto[]) => {
      sessionStorage.setItem('module-config', JSON.stringify(dtoList));
      if (enabled) {
        this.customToastService.showSuccess(this.translateService.instant('KONFIGURATION.MODUL_AKTIVIERUNG', {activationState: 'aktiviert'}));
      } else {
        this.customToastService.showSuccess(this.translateService.instant('KONFIGURATION.MODUL_AKTIVIERUNG', {activationState: 'deaktiviert'}));
      }
      // update label
      module.selectLabel = module.name + ' ('
        + (this.moduleConfigService.isModuleEnabled(module.module) ? 'aktiviert' : 'deaktiviert')
        + ')';
      const index = this.modules.findIndex(it => {
        return it.module === module.module
      })
      this.modules[index] = module;
      // we need to do this to trigger change detection...
      this.modules = [...this.modules];
    });
  }

  setVisibleConfig(config) {
    this.visibleConfig = config.module;
  }
}

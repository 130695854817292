<app-default-layout
  [title]="'STANDARD.FAHRKOSTEN_ANTRAEGE'"
>

  <div class="topbar">
    <div class="row">
      <div class="col-md-10 offset-md-1 ml-auto">
        <button
          class="btn btn-primary float-end"
          [routerLink]="['/pages/fahrkosten-detail']"
          data-cy="btn-add-fahrkosten"
        >
          <fa-icon [icon]="faPlus"></fa-icon>
          {{ "FAHRKOSTEN-TABLE.ADDBUTTON" | translate }}
        </button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-10 offset-md-1">
      <div class="frame frame-default">


        <ngx-datatable #table
          class="bootstrap"
          [rowHeight]="'auto'"
          [footerHeight]="50"
          [rows]="rows"
          [columns]="columns"
          [headerHeight]="40"
          [columnMode]="'force'"
          (activate)="onActivate($event)"
          [loadingIndicator]="isLoading"
          [sorts]="[{ prop: 'id', dir: 'desc' }]"
          [messages] = "{
            emptyMessage: 'NGX_DATATABLE.EMPTY' | translate,
            totalMessage: 'NGX_DATATABLE.TOTAL' | translate,
            selectedMessage: false
          }"
        >
          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
            >
              <div style="padding: 5px 10px">
                <div *ngIf="!isLoading && dataFetched"> <strong>{{ rowCount }}</strong> {{'NGX_DATATABLE.TOTAL' | translate}}</div>
                <div *ngIf="isLoading"> {{'NGX_DATATABLE.LOADING' | translate}} </div>
              </div>
              <datatable-pager
                [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'"
                [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'"
                [count]="rowCount"
                [hidden]="true"
                (change)="table.onFooterPage($event)">
              </datatable-pager>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>

        <ng-template #hdrTpl let-column="column" let-sort="sortFn">
            <div (click)="sort()">
              <fa-icon
               [icon]="this.sortInitial" >
              </fa-icon>
              <span style="padding-right:.5rem;" title="{{ 'FAHRKOSTEN-TABLE.COLUMNS.'+column.name | translate }}">
                <strong> {{ "FAHRKOSTEN-TABLE.COLUMNS."+column.name | translate }}</strong>
              </span>
            </div>
        </ng-template>

      </div>
    </div>
  </div>
</app-default-layout>

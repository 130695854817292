<app-default-layout [title]="">
  <div class="col-md-6 offset-md-3">
    <h2
      class="text-color-primary row justify-content-center"
      data-cy="deadend-title"
    >
      {{this.title}}
    </h2>
    <div
      class="row justify-content-center inline-flex fs-6"
      data-cy="deadend-content"
      [innerHtml]="this.content"
    >
    </div>
  </div>
</app-default-layout>

import {Component, HostListener, Input, OnInit} from '@angular/core';
import {faGear, faHouse, faRightFromBracket, faUser} from "@fortawesome/free-solid-svg-icons";
import {TextbausteinService} from "../../@core/services/textbaustein/textbaustein.service";
import {SessionService} from "../../@core/services/session/session.service";
import {KeyString} from "../../models/textbaustein/KeyString";
import {BehaviorSubject} from "rxjs";
import {AuthService} from "../../@core/services/auth/auth.service";
import {BenutzerDTO} from "../../models/benutzer/BenutzerDTO";
import {ConfigService} from "../../@core/config/services/config.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-navbar-responsive',
  templateUrl: './navbar-responsive.component.html',
  styleUrls: ['./navbar-responsive.component.scss']
})
export class NavbarResponsiveComponent implements OnInit {
  logoSource = "../assets/images/logo-white.png";
  safeLogoSource = this.sanitize(this.logoSource);

  // Logout timer
  @Input() public millisecUntilLogout$: BehaviorSubject<number>;
  @Input() public endDateForLogout$: BehaviorSubject<Date>;
  @Input() public loggedoutMessageVisible$ : BehaviorSubject<boolean>;
  private tokenLastRefreshed: Date = new Date();
  private tokenRefreshIntervalMilis = 60000; // 1 minute

  @Input() moduleEnabled: Array<Object> = [];
  @Input() roleEnabled : Array<Object> = [];
  @Input() benutzer : BenutzerDTO;

  existsSessionStorageTextbausteine: boolean = false;

  constructor(
    public textbausteinService: TextbausteinService,
    private sessionService: SessionService,
    private authService: AuthService,
    public configService: ConfigService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(){
    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });

    this.logoSource = this.configService?.logo?.data || this.logoSource;
    this.safeLogoSource = this.sanitize(this.logoSource);
  }

  @HostListener('document:click', ['$event'])
  onClick(): void {
    //global click event when clicking anywhere on the page
    //server call to elongate session instead of
    const now = new Date();
    if(this.endDateForLogout$?.value > now) {
      const elapsed = now.getTime() - this.tokenLastRefreshed.getTime();
      if(elapsed > this.tokenRefreshIntervalMilis) {
        this.authService.refreshToken();
        this.tokenLastRefreshed = now;
      }
    }
  }

  sanitize(url: string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  logout(): void {
    this.authService.logout('navbar-responsive');
  }

  protected readonly faAbmelden = faRightFromBracket;
  protected readonly faProfil = faUser;
  protected readonly faAccount = faGear;
  protected readonly faHouse = faHouse;
  protected readonly KeyString = KeyString;
}

import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TextbausteinDTO} from "../../../models/textbaustein/TextbausteinDTO";
import {TextbausteinService} from "../../../@core/services/textbaustein/textbaustein.service";
import {Router} from "@angular/router";
import {EMPTY, Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {faPlus, faSort} from '@fortawesome/free-solid-svg-icons';
import {CompositMessageService} from "../../../@core/services/compositMessage/compositMessage.service";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {nullLastComparator} from "../../../@core/utils/forms/null-last-comparator";

/**
 * deprecated since 2.1.7
 * ASM standalone ("hasSachbearbeiterbackend=false") is no longer supported
 */
@Component({
  selector: 'app-textbausteine',
  templateUrl: './textbausteine.component.html',
  styleUrls: ['./textbausteine.component.css']
})
export class TextbausteineComponent implements OnInit {

  @Input() rows$: Observable<TextbausteinDTO[]> = of([]);

  public rows: any;
  public columns: any;

  faPlus = faPlus;
  sortInitial = faSort;

  isLoading: boolean = false;
  dataFetched: boolean = false;

  entityNameTranslationKey: string = 'TEXTBAUSTEIN';

  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;
  @ViewChild("cellTplStringOnly", {static: true}) cellTplStringOnly: TemplateRef<any>;

  constructor(
    public router: Router,
    public textbausteinService: TextbausteinService,
    private customToastService: CustomToastService,
    private compositMessageService: CompositMessageService
  ) { }

  ngOnInit(): void {

    this.columns = [
      { name: 'key', headerTemplate: this.hdrTpl, maxWidth: 240, comparator: nullLastComparator.bind(this)  },
      { name: 'value', headerTemplate: this.hdrTpl, cellTemplate: this.cellTplStringOnly, comparator: nullLastComparator.bind(this) }
      // cellTplStringOnly suppresses [innerHTML] parser of ngx-datatable
    ];

    this.reloadTable();

  }

  onActivate(event) {
    if(event.type == 'click') {
      this.router.navigate(['/pages/textbausteine-detail/'+event.row.id ]).catch(()=>{});
    }
  }

  reloadTable() {
    this.isLoading = true;
    this.dataFetched = false;
    this.textbausteinService.getEditedTextbausteinList()
      .pipe(
        catchError((err) => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'getBulk'));
          this.isLoading = false;
          this.dataFetched = null;
          return EMPTY;
        })
      )
      .subscribe((data) => {
        this.rows = data;
        this.isLoading = false;
        this.dataFetched = this.rows.length>0;
      });
  }

}

import {Component, EventEmitter, Input, OnInit, Output, SecurityContext, SimpleChanges} from '@angular/core';
import {faCalendar, faCirclePlus, faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {BelegartService} from 'src/app/@core/services/belegart/belegart.service';
import {FahrzeugtypService} from 'src/app/@core/services/fahrzeugtyp/fahrzeugtyp.service';
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {FahrkostenDetailSbTrueComponent} from "../fahrkosten-detail-sb-true/fahrkosten-detail-sb-true.component";
import {DateiFahrkostenFullDTO} from "../../../models/fahrkosten/DateiFahrkostenFullDTO";
import {FahrkostenBelegDTO} from "../../../models/fahrkosten/FahrkostenBelegDTO";
import {DomSanitizer} from "@angular/platform-browser";
import {pairwise, startWith} from "rxjs/operators";
import {FieldConfigService} from "../../../@core/services/field-config/field-config.service";
import {ImageHelperService} from "../../../@core/utils/forms/image-helper.service";
import {toTitlecase} from "../../../@core/utils/forms/string-helper";
import {PFLICHTFELDMARKER} from "../../../../assets/constants/constants";
import {KeyString} from "../../../models/textbaustein/KeyString";
import {SessionService} from "../../../@core/services/session/session.service";
import {TextbausteinService} from "../../../@core/services/textbaustein/textbaustein.service";

@Component({
  selector: 'app-fahrkosten-universal',
  templateUrl: './fahrkosten-universal.component.html',
  styleUrls: ['./fahrkosten-universal.component.scss']
})
export class FahrkostenUniversalComponent implements OnInit {

  @Input() fahrkostenId : number;
  @Input() kostenId: number;
  @Input() fkkUniversal ;
  @Input() readonly : boolean;
  @Input() hasSB : boolean
  @Input() pflichtFeldMarker;
  @Input() arrayIndex : number;
  @Input() fieldConfig;
  @Input() visibleFields: string[];
  @Input() requiredFields : string[];
  @Input() isSubmitted: boolean;
  @Input() flipFlop: boolean;

  //send delete event to parent
  @Output() removeFromList = new EventEmitter<number>();

  faTrash = faCircleXmark;
  faPlus = faCirclePlus;
  faCalendar = faCalendar;

  monateList = [];
  jahreList = [];
  belegartList = [];
  fahrzeugtypList = [];
  optionBefoerderung = [];

  gefahreneTageList: Array<NgbDate> = [];
  gefahreneTageConfirmedList: Array<NgbDate> = [];
  datepickerStartDate;

  isInitializing: boolean = true;

  fahrkostenKostenUniversalForm: UntypedFormGroup;
  belegeList : Map<number,FahrkostenBelegDTO>;
  maxBelegId : number = 0;

  subscriptions = [];

  effectivelyVisible : string[] = [];
  effectiveValidators: string[] = [];
  neverValidate: string[] = ['hasSchuelermitnahme','mitfahrerBei','mitgenommen','mitfahrer1','mitfahrer2','mitfahrer3','mitfahrer4','mitfahrer5'];

  allowedNachweisFormats : string = ImageHelperService.ALLOWED_NACHWEIS_FILE_FORMATS;

  existsSessionStorageTextbausteine: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public translateService: TranslateService,
    private belegartService: BelegartService,
    private fahrzeugtypService: FahrzeugtypService,
    private customToastService: CustomToastService,
    private parent: FahrkostenDetailSbTrueComponent,
    private sanitizer: DomSanitizer,
    public fieldConfigService: FieldConfigService,
    private imageHelper: ImageHelperService,
    private sessionService: SessionService,
    public textbausteinService: TextbausteinService,
  ) { }

  ngOnInit(): void {

    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });

    this.makeInit().then(()=>{
      this.isInitializing = false;
      if(this.readonly)
        this.getFormItem('hasSchuelermitnahme').disable();
    }).catch(()=>{});

  }

  makeInit(): Promise<boolean>{
    return new Promise<boolean>((resolve)=>{
      this.isInitializing = true;
      this.initListsForSBtrue();
      this.optionBefoerderung = [
        {id: 'PKW', name: this.translateService.instant("BEFOERDERUNG.PKW")},
        {id: 'SONSTIGE', name: this.translateService.instant("BEFOERDERUNG.SONST")},
        {id: 'FAHRRAD', name: this.translateService.instant("BEFOERDERUNG.FAHRRAD")},
        {id: 'OEPNV', name: this.translateService.instant("BEFOERDERUNG.OEPNV")},
        {id: 'MITFAHRER', name: this.translateService.instant("BEFOERDERUNG.MITFAHRER")},
      ];
      if (this.fieldConfigService.getFieldFromFieldConfig('befoerderungSpezial', this.fieldConfig)?.visible) {
        this.optionBefoerderung = [...this.optionBefoerderung, {id: 'SPEZIAL', name: this.translateService.instant("BEFOERDERUNG.SPEZIAL")}];
      }
      this.monateList = Array.of('Januar','Februar','März','April','Mai','Juni','Juli','August','September','Oktober','November','Dezember');
      this.jahreList = Array.from({length: 11}, (_, i) => i + (new Date()).getFullYear()-10);

      this.initEmptyForm();
      this.createFormSubscriptions();
      this.autoPatchFromDTO(); // dto = fkkUniversal coming as @Input from parent
      this.recalcGesamtpreis();
      this.updateDatepickerStartDate();
      this.groupDateienByBelegId();
      this.befoerderungChanged();
      this.schuelermitnahmeChanged();

      if ( this.fkkUniversal.datum ) {
        this.fkkUniversal?.datum?.split(',').forEach(
          str => {
            let date = this.stringToNgbDate(str.trim());
            if( date !== null){
              this.gefahreneTageList.push(date);
            }
          }
        );
        this.updateDatepickerStartDate();
      }

      this.subscriptions.push(
        this.fahrkostenKostenUniversalForm.valueChanges
          .subscribe(() => {
            this.autoPatchToDTO();       // update local DTO
            setTimeout(() => {    // push updated DTO to parent : Fahrkosten
              let fkkUList = this.parent.fahrkostenForm.get('fahrkostenKostenUniversalList').value;
              fkkUList[this.arrayIndex] = this.fkkUniversal;
              this.parent.fahrkostenForm.patchValue({fahrkostenKostenUniversalList: fkkUList});
            });
          })
      );

      resolve(true);
    });
  }

  ngOnChanges(changes: SimpleChanges){ // changes received from parent via @Input
    if(changes.visibleFields && this.effectivelyVisible.length<1){
      this.effectivelyVisible  = this.visibleFields.slice();  // empty .slice() = copy-by-value, equivalent to Java's Arrays.copy()
      this.effectiveValidators = this.requiredFields.slice(); // empty .slice() = copy-by-value, equivalent to Java's Arrays.copy()
    }
    if(changes.isSubmitted || changes.flipFlop){
      this.clearAllValidators();
      if(this.isSubmitted===true){
        this.applyValidators().then(()=>{
          setTimeout(() => {
            this.parent.setChildrenValidity({
              kostenId: this.kostenId,
              isValid: this.fahrkostenKostenUniversalForm.valid,
              invalidControls: this.getNotValid() });
            this.parent.childrenResponses += 1;
          });
        }).catch(()=>{});
      } else {
        setTimeout(() => {
          this.parent.setChildrenValidity({kostenId: this.kostenId, isValid: true});
          this.parent.childrenResponses = 0;
        });
      }
      if(this.fahrkostenKostenUniversalForm?.invalid){
        console.log('invalid fields: ',this.getNotValid());
      }
    }
  }

  createFormSubscriptions() {
    this.subscriptions.push(
      this.fahrkostenKostenUniversalForm.get('befoerderung').valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (next !== prev) {
            this.befoerderungChanged();
          }
        }),
      this.fahrkostenKostenUniversalForm.get('jahr').valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (next !== prev) {
            this.zeitraumChanged();
          }
        }),
      this.fahrkostenKostenUniversalForm.get('monat').valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (next !== prev) {
            this.zeitraumChanged();
          }
        }),
      this.fahrkostenKostenUniversalForm.get('hasSchuelermitnahme').valueChanges
        .pipe(startWith(null), pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if (next !== prev) {
            this.schuelermitnahmeChanged();
          }
        }),
    );
  }

  schuelermitnahmeChanged(){
    let on, off;
    if(this.getFormItem('hasSchuelermitnahme').value===true){
      on = this.hasSchuelermitnahmeOnOff;
      off = [];
    } else {
      off = this.hasSchuelermitnahmeOnOff;
      on = [];
    }
    this.updateEffectivelyVisible(on,off);
  }

  befoerderungChanged(){
    let on, off;
    switch(this.getFormItem('befoerderung').value){
      case 'PKW': {
        on  = this.befPKWOnOff;
        off = [...this.befSONSTIGEOnOff,...this.befFAHRRADOnOff,...this.befOEPNVOnOff,...this.befSPEZIALOnOff,...this.befMITFAHREROnOff];
        break;
      }
      case 'SONSTIGE': {
        on  = this.befSONSTIGEOnOff;
        off = [...this.befPKWOnOff,...this.befFAHRRADOnOff,...this.befOEPNVOnOff,...this.befSPEZIALOnOff,...this.befMITFAHREROnOff];
        break;
      }
      case 'FAHRRAD': {
        on  = this.befFAHRRADOnOff;
        off = [...this.befPKWOnOff,...this.befSONSTIGEOnOff,...this.befOEPNVOnOff,...this.befSPEZIALOnOff,...this.befMITFAHREROnOff];
        break;
      }
      case 'OEPNV': {
        on  = this.befOEPNVOnOff;
        off = [...this.befPKWOnOff,...this.befSONSTIGEOnOff,...this.befFAHRRADOnOff,...this.befSPEZIALOnOff,...this.befMITFAHREROnOff];
        break;
      }
      case 'SPEZIAL': {
        on  = this.befSPEZIALOnOff;
        off = [...this.befPKWOnOff,...this.befSONSTIGEOnOff,...this.befOEPNVOnOff,...this.befFAHRRADOnOff,...this.befMITFAHREROnOff];
        break;
      }
      case 'MITFAHRER': {
        on  = this.befMITFAHREROnOff;
        off = [...this.befPKWOnOff,...this.befSONSTIGEOnOff,...this.befOEPNVOnOff,...this.befFAHRRADOnOff,...this.befSPEZIALOnOff];
        break;
      }
      default: {
        on  = [];
        off = [...this.befPKWOnOff,...this.befSONSTIGEOnOff,...this.befFAHRRADOnOff,...this.befOEPNVOnOff,...this.befSPEZIALOnOff,...this.befMITFAHREROnOff];
        break;
      }
    }
    this.updateEffectivelyVisible(on,off);
  }

  zeitraumChanged(){
    if(!(!this.getFormItem('datum').value)||!(!this.getFormItem('gefahreneTage').value)){
      this.fahrkostenKostenUniversalForm.patchValue({datum: undefined, gefahreneTage: undefined});
      this.gefahreneTageList = [];
      this.customToastService.showWarning('Ihre Datumsauswahl unter \'Gefahrene Tage\' wurde zurückgesetzt.');
    }
    this.updateDatepickerStartDate();
  }

  updateEffectivelyVisible(add: string[], remove: string[]){
    if(this.effectivelyVisible)
      remove.forEach(varName => {
        while(this.effectivelyVisible.includes(varName)){
          this.effectivelyVisible.splice(this.effectivelyVisible.indexOf(varName),1);
        }
        if(!this.readonly && !this.isInitializing)
          this.resetFormValues([varName]);
      });
    add.forEach(varName => {
      if( !this.effectivelyVisible.includes(varName)&&this.visibleFields.includes(varName)){
        this.effectivelyVisible = [...this.effectivelyVisible,varName];
      }
    });
    this.updateEffectiveValidators();
  }

  updateEffectiveValidators(){
    this.effectiveValidators = [];
    if(this.effectivelyVisible.length > 0) {
      this.requiredFields.forEach(varName => {
        if (this.effectivelyVisible.includes(varName) && !this.neverValidate.includes(varName))
          this.effectiveValidators = [...this.effectiveValidators,varName];
      });
    }
    if(this.isSubmitted===true)
      this.applyValidators().catch(()=>{});
  }

  applyValidators(): Promise<any> {
    return new Promise ((resolve) => {
      this.clearAllValidators();
      this.effectiveValidators.forEach((varName)=>{
        let varNames;
        if(varName.indexOf('mitfahrer')>-1){
          varNames = [varName+'Name',varName+'Vorname',varName+'Anzahl'];
        } else {
          varNames = [varName];
        }
        varNames.forEach(vN=>{
          this.getFormItem(vN)?.setValidators([Validators.required]);
          this.getFormItem(vN)?.updateValueAndValidity({onlySelf: true, emitEvent: false});

          //this is a trick to make validators register values that were already in field,
          //when .updateValueAndValidity() was called
          //or values that have been loaded programmatically
          //also needed when someone tries to save an empty form,
          //for some reason markAsTouched, markAsDirty, markAllAsTouched all have no effect
          if(this.getFormItem(vN)?.disabled){// if off, turn on and off again
            this.getFormItem(vN)?.enable(); this.getFormItem(vN)?.disable();
          } else { //if on, turn off and on again
            this.getFormItem(vN)?.disable(); this.getFormItem(vN)?.enable();
          }
        });
      });
      resolve('hurra');
    });
  }

  resetFormValues(varnames: string[]){
    varnames.forEach((varname:string) => {
      let vars;
      if(varname.indexOf('mitfahrer')>-1)
        vars = [varname+'Name',varname+'Vorname',varname+'Anzahl'];
      else
        vars = [varname];
      vars.forEach(vN=>{
        if(this.getFormItem(vN))
          this.getFormItem(vN).reset();
      })
    });
  }

  clearAllValidators(){
    if(this.fahrkostenKostenUniversalForm) {
      const controls = this.fahrkostenKostenUniversalForm.controls;
      for (const name in controls) {
        controls[name].clearAsyncValidators();
        controls[name].clearValidators();
        controls[name].setErrors(null); // remove invalid status
      }
    }
  }

  getNotValid() : string[] {
    let notValid: string[] = [];
    const controls = this.fahrkostenKostenUniversalForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        let temp = document.getElementById('sfklabel-'+name)
        notValid.push(
          temp?.innerHTML.replace(''+this.pflichtFeldMarker,'').trim()
          || this.fieldConfigService.getFieldLabel(name, this.fieldConfig, toTitlecase(name)));
      }
    }
    return notValid;
  }

  recalcGesamtpreis(){
    if (this.fahrkostenKostenUniversalForm.get('einzelpreis')?.value) {
      if (this.fahrkostenKostenUniversalForm.get('einzelpreis')?.value?.toString().includes(',')) {
        this.fahrkostenKostenUniversalForm.get('einzelpreis')?.patchValue(this.fahrkostenKostenUniversalForm.get('einzelpreis')?.value.replace(',', '.'));
      }
    }
    if(!(!this.fahrkostenKostenUniversalForm.get('belegAnzahl')?.value) &&
      !(!this.fahrkostenKostenUniversalForm.get('einzelpreis')?.value) ) {
      const gesamt = this.fahrkostenKostenUniversalForm.get('belegAnzahl').value
        * this.fahrkostenKostenUniversalForm.get('einzelpreis').value;
      this.fahrkostenKostenUniversalForm.patchValue({gesamtpreis: gesamt});
    } else {
      this.fahrkostenKostenUniversalForm.patchValue({gesamtpreis: undefined});
    }
  }

  autoPatchToDTO(){
    const controls = this.fahrkostenKostenUniversalForm.controls;
    for (const varname in controls) {
      let formValue = null;
      if(this.getFormItem(varname)){
        formValue = this.getFormItem(varname).value;
      }
      if( formValue?.length !== 0 || formValue === undefined ){
        this.fkkUniversal[varname] = formValue;
      } else {
        this.fkkUniversal[varname] = null;
      }
    }
  }

  deleteFkkUniversal(){
    this.removeFromList.emit(this.arrayIndex);
  }

  autoPatchFromDTO(){
    if(!(!this.fkkUniversal)&&!(!this.fahrkostenKostenUniversalForm)){
      const controls = this.fahrkostenKostenUniversalForm.controls;
      for (const name in controls) {
        try{
          this.fahrkostenKostenUniversalForm.patchValue({[name] : this.fkkUniversal[name]});
        } catch (e) {
          // do nothing
        }
      }
    }
  }

  initEmptyForm(){
    this.fahrkostenKostenUniversalForm = this.fb.group({
      id: undefined,
      fahrkostenId: undefined,
      kostenId: undefined,
      befoerderung: undefined,
      jahr: undefined,
      monat: undefined,
      belegArt: undefined,
      belegAnzahl: undefined,
      einzelpreis: undefined,
      gesamtpreis: undefined,
      korrekturAnzahl: undefined,
      korrekturEinzelpreis: undefined,
      korrekturGesamtpreis: undefined,
      fahrzeugtyp: undefined,
      kennzeichen: undefined,
      gefahreneTage: undefined,
      gruende: undefined,
      mitfahrerBei: undefined,
      mitgenommen: undefined,
      datum: undefined,
      datumConfirmed: undefined,
      gefahreneTageConfirmed: undefined,
      erstattungFuerPkwnutzung: undefined,

      //children
      dateiFahrkostenList: undefined,

      //mitfahrer
      hasSchuelermitnahme: undefined,
      mitfahrer1Name: undefined,
      mitfahrer1Vorname: undefined,
      mitfahrer1Anzahl: undefined,
      mitfahrer2Name: undefined,
      mitfahrer2Vorname: undefined,
      mitfahrer2Anzahl: undefined,
      mitfahrer3Name: undefined,
      mitfahrer3Vorname: undefined,
      mitfahrer3Anzahl: undefined,
      mitfahrer4Name: undefined,
      mitfahrer4Vorname: undefined,
      mitfahrer4Anzahl: undefined,
      mitfahrer5Name: undefined,
      mitfahrer5Vorname: undefined,
      mitfahrer5Anzahl: undefined,
    });
  }

  initListsForSBtrue(){
    this.belegartService.getBelegarten().subscribe( belegarten => this.belegartList = belegarten);
    this.fahrzeugtypService.getFahrzeugtypen().subscribe( fahrzeugtypen => this.fahrzeugtypList = fahrzeugtypen);
  }

  getFormItem(s: string) {
    return this.fahrkostenKostenUniversalForm?.get(s);
  }

  // BELEGE & FILEREADER

  getBelegeMapKeys(){
    // this method is needed here, because calling this.belegeList.keys() directly in html
    // would cause "expression has changed" error for the iterable
    if(this.belegeList)
      return Array.from(this.belegeList.keys());
    else
      return [];
  }

  deleteBeleg(belegId: number){
    let beleg = this.belegeList.get(belegId);
    let dateiFkList = this.getFormItem('dateiFahrkostenList').value;
    beleg.seiten.forEach( dateiFahrkostenFullDTO => dateiFkList.splice(dateiFkList.indexOf(dateiFahrkostenFullDTO),1) );
    this.fahrkostenKostenUniversalForm.patchValue({dateiFahrkostenList: dateiFkList});
    this.belegeList.delete(belegId);
  }

  addBeleg({currentTarget}){
    let file : File = currentTarget.files[0];
    this.imageHelper.handleBelegFile(file)
      .then((processed)=>{
        if(processed.error) {
          this.customToastService.showError(processed.error);
          return;
        }
        this.maxBelegId += 1;
        let dateiFkDto = this.sourceToDateiFahrkostenFullDto(file.type,processed.image);
        let belegeList: Map<number,FahrkostenBelegDTO> = this.belegeList || new Map<number,FahrkostenBelegDTO>();
        let seiten  = new Array<DateiFahrkostenFullDTO>;
        let sources = new Array<any>;
        seiten.push(dateiFkDto);
        sources.push(processed.image);
        let dateiList = this.getFormItem('dateiFahrkostenList')?.value || new Array<DateiFahrkostenFullDTO>();
        dateiList.push(dateiFkDto);
        this.fahrkostenKostenUniversalForm.patchValue({dateiFahrkostenList: dateiList});
        belegeList.set(this.maxBelegId,{
          belegId: this.maxBelegId,
          kostenId: this.kostenId,
          fahrkostenId: this.fahrkostenId,
          seiten: seiten,
          sources: sources
        });
        this.belegeList = belegeList;
      }).catch(()=>{});
  }

  sourceToDateiFahrkostenFullDto(filetype: string, data: any) {
    let dateiFahrkostenFullDTO : DateiFahrkostenFullDTO = {} as DateiFahrkostenFullDTO;
    dateiFahrkostenFullDTO.id           = undefined;
    dateiFahrkostenFullDTO.contentType  = filetype;
    dateiFahrkostenFullDTO.erstellt     = new Date();
    dateiFahrkostenFullDTO.fahrkostenId = this.fahrkostenId;
    dateiFahrkostenFullDTO.denied       = false;
    dateiFahrkostenFullDTO.kostenId     = this.kostenId;
    dateiFahrkostenFullDTO.belegId      = this.maxBelegId;
    dateiFahrkostenFullDTO.seiteId      = 1;
    dateiFahrkostenFullDTO.imgBytes     = data.split('base64,')[1];
    return dateiFahrkostenFullDTO;
  }

  processForView(dFkDto: DateiFahrkostenFullDTO) : Promise<any> {
    return new Promise<any>( (resolve) => {
      if( !dFkDto.contentType || !dFkDto.imgBytes ){
        resolve(this.sanitize('../../../../../assets/images/icons/image_not_supported_black_24dp.svg'));
        return;
      }
      if( !dFkDto.contentType.includes('pdf') ){ // image files

        // fix for weird stuff in Bestandssystem
        // TODO: diese Korrekturen wenn möglich direkt bei der Migration machen
        if(dFkDto.contentType.includes('JPG-Datei')) {
          dFkDto.contentType = dFkDto.contentType.replace('JPG-Datei', 'image/jpeg');
        } else if(dFkDto.contentType.includes('BMP-Datei')) {
          dFkDto.contentType = dFkDto.contentType.replace('BMP-Datei', 'image/bmp');
        } else if(dFkDto.contentType.includes('PNG-Datei')) {
          dFkDto.contentType = dFkDto.contentType.replace('PNG-Datei', 'image/png');
        }

        let url = this.arrayBufferToBase64(new Uint8Array(dFkDto.imgBytes));
        let dataUrl = 'data:'+dFkDto.contentType+';base64,'+url;
        if( dFkDto.imgBytes.length > ImageHelperService.BYTEA_UPLOAD_MAXSIZE ) {
          this.customToastService.showWarning('Ein Beleg überschreitet die max. zulässige Größe von '
            + ImageHelperService.BYTEA_UPLOAD_MAXSIZE / 1024 / 1024 + ' MB. ' +
            'Wenn Sie den Antrag speichern, wird das Bild irreversibel komprimiert.');
          this.imageHelper.compressImage(dataUrl,dataUrl.length)
            .then((compressedImage) => {
              dFkDto.imgBytes = compressedImage.split('base64,')[1]
              resolve(this.sanitize(compressedImage))
              return;
            }).catch((err) => {console.log("compression err",err)});
        } else {
          resolve(this.sanitize(dataUrl))
          return;
        }
      } else { // pdf files
        if( dFkDto.imgBytes.length > ImageHelperService.BYTEA_UPLOAD_MAXSIZE )
          this.customToastService.showWarning('Eine Beleg-PDF überschreitet die max. zulässige Größe von '
            + ImageHelperService.BYTEA_UPLOAD_MAXSIZE/1024/1024 + ' MB. Solange Sie die Größe nicht reduzieren, kann der Antrag nicht gespeichert werden.' );
        let url = this.arrayBufferToBase64(new Uint8Array(dFkDto.imgBytes));
        let dataUrl = 'data:'+dFkDto.contentType+';base64,'+url;
        resolve( this.sanitize(dataUrl));
        return;
      }
    });
  }

  groupDateienByBelegId(){
    let dateiFahrkostenList = this.fahrkostenKostenUniversalForm?.get('dateiFahrkostenList')?.value;
    let belegeList = new Map<number,FahrkostenBelegDTO>;
    if( !!dateiFahrkostenList && dateiFahrkostenList.length > 0 ){
      this.maxBelegId = Math.max(...dateiFahrkostenList.map(o => o.belegId));
      dateiFahrkostenList.sort((a, b) => a.seiteId - b.seiteId).forEach(dfKFullDto => {
        if(belegeList.has(dfKFullDto.belegId)){ // existing beleg
          let temp = belegeList.get(dfKFullDto.belegId);
          temp.seiten.push(dfKFullDto);
          this.processForView(dfKFullDto)
            .then((processed) => temp.sources.push(processed))
            .catch(() => {})
          ;
          belegeList.set(dfKFullDto.belegId,temp);
        } else { // create new beleg
          let temp1 = new Array<DateiFahrkostenFullDTO>();
          let temp2 = new Array<DateiFahrkostenFullDTO>();
          temp1.push(dfKFullDto);
          this.processForView(dfKFullDto)
            .then((processed) => temp2.push(processed))
            .catch(() => {})
          ;
          belegeList.set(dfKFullDto.belegId,{
            belegId : dfKFullDto.belegId,
            kostenId : this.fkkUniversal?.kostenId || 1,
            fahrkostenId : this.fkkUniversal?.fahrkostenId || undefined,
            seiten: temp1,
            sources: temp2
          });
        }
      });
    } else {
      this.maxBelegId = 0;
    }
    this.belegeList = belegeList;
    this.fahrkostenKostenUniversalForm.patchValue({belegAnzahl: this.belegeList.size})
  }

  arrayBufferToBase64( bytes ) {
    let binary = '';
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
  }

  sanitize(url: string){
    return this.sanitizer.sanitize(SecurityContext.URL,this.sanitizer.bypassSecurityTrustUrl(url));
  }

  //DATEPICKER SECTION

  stringToNgbDate(date: string) : NgbDate{
    if(date){
      return new NgbDate(Number(date.substring(0,4)),Number(date.substring(4,6)),Number(date.substring(6,8)));
    }
    return null;
  }

  isInGefahreneTage(date: NgbDate, selector: string) : boolean {
    let returnval = false;
    let list = [];
    if(selector === 'gefahreneTage') {
      list = this.gefahreneTageList;
    } else if (selector === 'gefahreneTageConfirmed') {
      list = this.gefahreneTageConfirmedList;
    } else if(selector === 'both') {
      return (this.isInGefahreneTage(date,'gefahreneTage')&&this.isInGefahreneTage(date,'gefahreneTageConfirmed'));
    }
    if(!list || list.length===0) { return false;}
    list.forEach((element) => {
      if (element.equals(date)) {
        returnval = true;
      }
    });
    return returnval;
  }

  selectionDate(selectedDate: NgbDate){

      let found = false;
      this.gefahreneTageList.forEach((element,index)=>{
        if(element.equals(selectedDate)) { // remove Date, if already present
          this.gefahreneTageList.splice(index, 1);
          found = true;
        }
      });
      if(!found)
      { // add if not already present
        this.gefahreneTageList.push(selectedDate);
      }
      let datumstrings = '';
      this.gefahreneTageList.forEach(
        ngbdate => datumstrings += this.ngbDateToString(ngbdate) + ','
      );
      if(this.fkkUniversal) {
        this.fkkUniversal.datum = datumstrings;
        this.fkkUniversal.gefahreneTage = this.gefahreneTageList.length;
      }
      this.fahrkostenKostenUniversalForm.patchValue({
        gefahreneTage: this.gefahreneTageList.length,
        datum: datumstrings,
      });
  }

  ngbDateToString(ngbdate: NgbDate): string{
    if(ngbdate){
      return ''+ngbdate.year+String(ngbdate.month).padStart(2, '0')+String(ngbdate.day).padStart(2, '0');
    }
    return null;
  }

  updateDatepickerStartDate(){
    let yea: number;
    let mon: number;
    if (this.getFormItem('jahr').touched && this.getFormItem('jahr').valid) {
      yea = Number(this.getFormItem('jahr').value);
    } else if (this.fkkUniversal && this.fkkUniversal.jahr !== '0' && this.fkkUniversal.jahr !== null) {
      yea = Number(this.fkkUniversal.jahr);
    } else {
      yea = new Date().getFullYear();
    }
    if (this.getFormItem('monat').touched && this.getFormItem('monat').valid) {
      mon = this.monateList.indexOf(this.getFormItem('monat').value);
      if( mon ===null ){
        mon = this.getFormItem('monat').value;
      }
      if( mon ===null ){
        mon = 0;
      }
    } else if (this.fkkUniversal && this.fkkUniversal.monat !== '' && this.fkkUniversal.monat !== null) {
      mon = this.monateList.indexOf(this.fkkUniversal.monat);
      if( mon === null ){
        mon = this.monateList.indexOf(this.getFormItem('monat').value);
      }
      if( mon === null ){
        mon = 0;
      }
    } else if(!this.fkkUniversal) {
      mon = new Date().getMonth()-1;
    }
    this.datepickerStartDate = {year: yea,month: mon+1};
  }

  // destroy
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // ONOFF
  befPKWOnOff       = ['gefahreneTage','kennzeichen','gruende','mitgenommen','hasSchuelermitnahme'];
  befSONSTIGEOnOff  = ['gefahreneTage','kennzeichen','gruende','mitgenommen','fahrzeugtyp','hasSchuelermitnahme'];
  befFAHRRADOnOff   = ['gefahreneTage',];
  befOEPNVOnOff     = ['gefahreneTage','belegArt','belegAnzahl','einzelpreis','gesamtpreis','gruende','belegUpload'];
  befSPEZIALOnOff   = ['gefahreneTage','belegArt','belegAnzahl','einzelpreis','gesamtpreis','gruende','belegUpload'];
  befMITFAHREROnOff = ['gefahreneTage','gruende','mitfahrerBei']
  hasSchuelermitnahmeOnOff = ['mitfahrer1','mitfahrer2','mitfahrer3','mitfahrer4','mitfahrer5'];

  getFieldLabelOrTranslate(fieldName: string, translateKey: string): string {
    return ''+(this.effectiveValidators.includes(fieldName)?PFLICHTFELDMARKER:'')
      +this.fieldConfigService.getFieldLabel(fieldName, this.fieldConfig, this.translateService.instant(translateKey));
  }

  getTextbausteinOrTranslate(textbausteinKey: KeyString, translateKey: string): string {
    return this.existsSessionStorageTextbausteine?
      this.textbausteinService.printTextbausteinByKey(textbausteinKey, translateKey?
        this.translateService.instant(translateKey) : '') : '';
  }

}

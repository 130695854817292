import {Component, OnInit} from '@angular/core';
import {BenutzerDTO} from "../../models/benutzer/BenutzerDTO";
import {
  faInfoCircle,
  faSave, IconDefinition
} from '@fortawesome/free-solid-svg-icons';
import {BenutzerService} from "../../@core/services/benutzer/benutzer.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {CustomToastService} from "../../@core/utils/custom-toast.service";
import {catchError} from "rxjs/operators";
import {EMPTY, Subscription} from "rxjs";
import {NgxSpinnerService} from "ngx-spinner";
import {CompositMessageService} from "../../@core/services/compositMessage/compositMessage.service";
import {GeschlechtHelperService} from "../../@core/utils/forms/geschlecht-helper.service";
import {Land} from "../../models/land/Land";
import {ValidationHelperService} from "../../@core/utils/forms/validation-helper.service";
import {PostleitzahlenService} from "../../@core/services/postleitzahlen/postleitzahlen.service";
import {PlzValidationHelperService} from "../../@core/utils/forms/plz-validation-helper.service";
import {BenutzerSessionService} from "../../@core/services/benutzer/benutzer-session.service";
import {createVormundAgeValidator, DateTimeHelperService} from "../../@core/utils/forms/datetime-helper.service";
import {ModuleConfigService} from "../../@core/services/module-config/module-config.service";
import {KeyString} from "../../models/textbaustein/KeyString";
import {TextbausteinService} from "../../@core/services/textbaustein/textbaustein.service";
import {SessionService} from "../../@core/services/session/session.service";
import {FieldConfigService} from "../../@core/services/field-config/field-config.service";
import {PFLICHTFELDMARKER} from "../../../assets/constants/constants";
import {SchuleService} from "../../@core/services/schule/schule.service";

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit {

  benutzer: BenutzerDTO;
  benutzerForm: UntypedFormGroup;
  entityNameTranslationKey : string = 'PROFIL';
  optionGeschlecht = [];
  landList = [];
  schuleList = [];

  formItemSubscriptions: Subscription[] = [];

  today : string;
  minDateBirthday : string;
  maxDate : string;

  existsSessionStorageTextbausteine = false;

  fieldConfig;

  constructor(
    private benutzerService: BenutzerService,
    private fb: UntypedFormBuilder,
    public translate: TranslateService,
    private customToastService: CustomToastService,
    public translateService: TranslateService,
    private compositMessageService: CompositMessageService,
    private spinner: NgxSpinnerService,
    private geschlechtHelperService: GeschlechtHelperService,
    private validationHelperService: ValidationHelperService,
    private postleitzahlenService: PostleitzahlenService,
    public plzHelper: PlzValidationHelperService,
    private benutzerSessionService: BenutzerSessionService,
    private moduleConfigService: ModuleConfigService,
    private datetimeHelper: DateTimeHelperService,
    private sessionService: SessionService,
    public textbausteinService: TextbausteinService,
    private fieldConfigService: FieldConfigService,
    private schuleService: SchuleService
  ) {
    this.loadFieldConfig();
    this.initEmptyForm();
    this.initBlankBenutzer();

    this.minDateBirthday = DateTimeHelperService.BIRTHDATE_MINIMUM;

    if (this.moduleConfigService.isModuleEnabled('onlyAdultRegistrations')) {
      this.maxDate = this.datetimeHelper.maxDateVormund();
      this.getFormItem('geburtsdatum')?.setValidators([Validators.required, createVormundAgeValidator()]);
      this.getFormItem('geburtsdatum')?.updateValueAndValidity();
    } else {
      this.maxDate = this.datetimeHelper.formattedToday();
    }
  }

  ngOnInit(): void {

    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });

    this.schuleService.getSchuleListBulk()
      .subscribe(schulen => this.schuleList = schulen);

    // wait for translate file to be initialized
    this.translateService.get('dummyTranslation').subscribe(() => {
      this.optionGeschlecht = this.geschlechtHelperService.getGeschlechtOptionList();
    });

    this.landList = Object.keys(Land).map((name) => {
      return { name, value: Land[name as keyof typeof Land],};
    });

    let smallBenutzer: BenutzerDTO = JSON.parse(sessionStorage.getItem('user')) as BenutzerDTO ;
    this.getBenutzer(smallBenutzer.id);
  }

  getFormItem(s: string) {
    return this.benutzerForm?.get(s);
  }

  getBenutzer(id: number) {
    this.benutzerService.getBenutzer(id.toString())
      .pipe(
        catchError(err => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'get'));
          return EMPTY;
        })
      )
      .subscribe((data: BenutzerDTO) => {
        this.benutzer = { ...data };
        this.patchBenutzerToForm();
      });
  }

  updateBenutzer() {
    this.applyFieldConfigValidators().then(()=>{
      if(!this.benutzerForm.valid){
        this.customToastService.showError(this.compositMessageService.createIncompleteMessage(this.entityNameTranslationKey));
        return;
      }
      this.finalizeUpdate();
    });
  }

  finalizeUpdate(){
    this.spinner.show();
    this.benutzerService.updateBenutzer(this.benutzer.id.toString(),
      {
        name: this.getFormItem('name')?.value?.length !== 0 ? this.getFormItem('name').value : undefined,
        vorname: this.getFormItem('vorname')?.value?.length !== 0 ? this.getFormItem('vorname').value : undefined,
        geburtsdatum: this.getFormItem('geburtsdatum')?.value?.length !== 0 ? this.getFormItem('geburtsdatum').value : undefined,
        strasse: this.getFormItem('strasse')?.value?.length !== 0 ? this.getFormItem('strasse').value : undefined,
        adresszusatz: this.getFormItem('adresszusatz')?.value?.length !== 0 ? this.getFormItem('adresszusatz').value : undefined,
        plz: this.getFormItem('plz')?.value?.length !== 0 ? this.getFormItem('plz').value : undefined,
        ort: this.getFormItem('ort')?.value?.length !== 0 ? this.getFormItem('ort').value : undefined,
        emailNeu: undefined,
        kennwortNeu: undefined,
        ablaufdatum: undefined,
        schuelernummer: undefined,
        screenname: undefined,
        telefonnummer: this.getFormItem('telefonnummer')?.value?.length !== 0 ? this.getFormItem('telefonnummer').value : undefined,
        geschlecht: this.getFormItem('geschlecht')?.value?.length !== 0 ? this.getFormItem('geschlecht').value : undefined,
        abonnementnummer: this.getFormItem('abonnementnummer')?.value?.length !== 0 ? this.getFormItem('abonnementnummer').value : undefined,
        iban: undefined,
        kontoinhaber: undefined,
        schuleId: this.getFormItem('schule')?.value || undefined,
        roles: undefined,
        gesperrt: undefined,
        accountLocked: undefined,
        accountExpired: undefined,
        land: this.getFormItem('land')?.value?.length !== 0 ? this.getFormItem('land').value : undefined,
        bic: undefined,
        nameKreditinstitut: undefined
      })
      .pipe(
        catchError(err => {
          this.customToastService.showError(this.compositMessageService.createErrorMessage(err,this.entityNameTranslationKey,'patch'));
          this.benutzerForm?.patchValue({emailNeu: undefined});
          this.benutzerForm?.patchValue({kennwortNeu: undefined});
          this.spinner.hide();
          return EMPTY;
        })
      )
      .subscribe((data: BenutzerDTO) => {
        this.benutzer = { ...data };
        this.patchBenutzerToForm();
        this.customToastService.showSuccess(this.compositMessageService.createSuccessMessage(this.entityNameTranslationKey,'patch'));
        sessionStorage.setItem('user', JSON.stringify(data));
        this.benutzerSessionService.broadcastBenutzerChange();
        // update profile name inside header
        document.getElementById('dropdownBasic1').innerHTML = this.benutzer.vorname + ' ' + this.benutzer.name;
        this.spinner.hide();
      });
  }

  patchBenutzerToForm(){
    this.benutzerForm.patchValue({
      name: this.benutzer.name || undefined,
      vorname: this.benutzer.vorname || undefined,
      schule: this.benutzer.schule?.id || undefined,
      strasse: this.benutzer.strasse || undefined,
      adresszusatz: this.benutzer.adresszusatz || undefined,
      plz: this.benutzer.plz || undefined,
      ort: this.benutzer.ort || undefined,
      land: this.benutzer.land || undefined,
      geschlecht: this.benutzer.geschlecht || undefined,
      geburtsdatum: this.benutzer.geburtsdatum || undefined,
      telefonnummer: this.benutzer.telefonnummer || undefined,
      abonnementnummer: this.benutzer.abonnementnummer || undefined,
      email: this.benutzer.email || undefined,
    });
  }

  ngOnDestroy(){
    this.formItemSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  setOrt(event, formItem: string) {
    this.postleitzahlenService.getPostleitzahlByPlz(event.target.value).subscribe(result => {
      if (result !== null) {
        this.getFormItem(formItem)?.patchValue(result.ort);
      }
    });
  }

  getMaxLength(plzControlname:string): number {
    return this.plzHelper.getMaxLength(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value);
  }
  getPattern(plzControlname:string): string {
    return this.plzHelper.getPattern(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value);
  }

  initBlankBenutzer(){
    this.benutzer = {
      id: undefined,
      name: undefined,
      vorname: undefined,
      telefonnummer: undefined,
      email: undefined,
      strasse: undefined,
      adresszusatz: undefined,
      geburtsdatum: new Date(),
      ablaufdatum: new Date(),
      ort: undefined,
      plz: undefined,
      geschlecht: undefined,
      schule: {
        id: undefined,
        version: undefined,
        name: undefined,
        schulnummer: undefined,
        hinweistext: undefined,
        berufskolleg: undefined,
        eigenanteil: undefined,
        schultyp: undefined,
        tickets: undefined,
        ticketsa: undefined,
        klassen: undefined,
        haltestellen: undefined,
        bildungsgaenge: undefined,
        schuleKoordinaten: undefined
      },
      iban: undefined,
      bic: undefined,
      nameKreditinstitut: undefined,
      kontoinhaber: undefined,
      accountExpired:undefined,
      schuelernummer:undefined,
      accountLocked:undefined,
      gesperrt: undefined,
      status:undefined,
      abonnementnummer:undefined,
      roles:undefined,
      screenname:undefined,
      land: undefined
    };
  }

  initEmptyForm(){
    this.benutzerForm = this.fb.group({
        name: undefined,
        vorname: undefined,
        schule: undefined,
        telefonnummer: undefined,
        email: {value: undefined, disabled: true},
        geburtsdatum: undefined,
        strasse: [undefined, [Validators.pattern(this.validationHelperService.strasseHausnummerPattern)]],
        adresszusatz: [undefined],
        plz: [undefined],
        ort: [undefined],
        land: [undefined],
        geschlecht: [undefined],
        abonnementnummer: [undefined,Validators.pattern(this.validationHelperService.abonummerPattern)],
      }
    )
  }

  loadFieldConfig(){
    this.fieldConfig = this.fieldConfigService.getFieldConfigFromSessionStorage(['benutzerProfilASM']);
    if(!this.fieldConfig || this.fieldConfig.length <1){
      console.log('No fieldConfig for Profil found: setting default values');
      this.setFieldConfigDefault();
    }
  }

  getTextbausteinOrTranslate(textbausteinKey: KeyString, translateKey: string): string {
    return this.existsSessionStorageTextbausteine?
      this.textbausteinService.printTextbausteinByKey(textbausteinKey, translateKey?
        this.translateService.instant(translateKey) : '') : '';
  }

  isVisible(fieldName: string): boolean {
    return this.fieldConfigService.getFieldFromFieldConfig(fieldName, this.fieldConfig)?.visible;
  }

  isRequired(fieldName: string): boolean {
    return this.fieldConfigService.getFieldFromFieldConfig(fieldName, this.fieldConfig)?.required;
  }

  getFieldLabelOrTranslate(fieldName: string, translateKey: string): string {
    return ''+(this.isRequired(fieldName)?PFLICHTFELDMARKER:'')
      +this.fieldConfigService.getFieldLabel(fieldName, this.fieldConfig, this.translateService.instant(translateKey));
  }

  applyFieldConfigValidators() : Promise<any>{
    return new Promise<void>((resolve)=>{
      if(this.fieldConfig&&this.fieldConfig.length>0){
        this.fieldConfig.forEach( entry => {
          if( entry.visible && entry.required ){
            this.benutzerForm.get(entry.field).addValidators([Validators.required]);
            this.benutzerForm.get(entry.field).updateValueAndValidity();
          }
          if(entry.field==='email'){
            // this is only needed because cypress does not patch values into readonly fields
            // and then this "empty" email field fails validation
            this.benutzerForm.get(entry.field).setErrors(null);
          }
        });
      }
      resolve();
    });
  }

  setFieldConfigDefault(){
    this.fieldConfig = [
      {field: 'name', visible: true, required: true, label: null, module: 'benutzerProfilASM'},
      {field: 'vorname', visible: true, required: true, label: null, module: 'benutzerProfilASM'},
      {field: 'telefonnummer', visible: true, required: false, label: null, module: 'benutzerProfilASM'},
      {field: 'email', visible: true, required: true, label: null, module: 'benutzerProfilASM'},
      {field: 'geburtsdatum', visible: true, required: true, label: null, module: 'benutzerProfilASM'},
      {field: 'strasse', visible: true, required: true, label: null, module: 'benutzerProfilASM'},
      {field: 'adresszusatz', visible: true, required: false, label: null, module: 'benutzerProfilASM'},
      {field: 'plz', visible: true, required: true, label: null, module: 'benutzerProfilASM'},
      {field: 'ort', visible: true, required: true, label: null, module: 'benutzerProfilASM'},
      {field: 'land', visible: true, required: false, label: null, module: 'benutzerProfilASM'},
      {field: 'geschlecht', visible: true, required: false, label: null, module: 'benutzerProfilASM'},
      {field: 'abonnementnummer', visible: true, required: false, label: null, module: 'benutzerProfilASM'},
    ];
  }

  public readonly faSave : IconDefinition = faSave;
  public readonly faInfo : IconDefinition = faInfoCircle;

  protected readonly KeyString = KeyString;
}

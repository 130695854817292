import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {
  faCheckCircle,
  faCirclePlus,
  faClock,
  faInfoCircle,
  faPlus,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {catchError, pairwise, startWith} from "rxjs/operators";
import {EMPTY, Subscription} from "rxjs";
import {SchuelerService} from "../../../@core/services/schueler/schueler.service";
import {CustomToastService} from "../../../@core/utils/custom-toast.service";
import {Router} from "@angular/router";
import {FahrkostenDTO} from "../../../models/fahrkosten/FahrkostenDTO";
import {FahrkostenService} from "../../../@core/services/fahrkosten/fahrkosten.service";
import {FahrkostenStatus} from "../../../models/fahrkosten/FahrkostenStatus";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {SchuelerDTO} from "../../../models/schueler/SchuelerDTO";
import {FieldConfigService} from 'src/app/@core/services/field-config/field-config.service';
import {PictureFileComponent} from "../../picture-file/picture-file/picture-file.component";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FahrkostenCreateDTO} from "../../../models/fahrkosten/FahrkostenCreateDTO";
import {FahrkostenUpdateDTO} from "../../../models/fahrkosten/FahrkostenUpdateDTO";
import {FahrkostenDetailParentComponent} from "../fahrkosten-detail-parent/fahrkosten-detail-parent.component";
import {DomSanitizer} from "@angular/platform-browser";
import {ConfirmComponent} from "../../../shared/confirm/confirm.component";
import {FahrkostenKostenUniversalDTO} from 'src/app/models/fahrkosten/FahrkostenKostenUniversalDTO';
import {TextbausteinService} from "../../../@core/services/textbaustein/textbaustein.service";
import {ValidatorService} from "angular-iban";
import {SchuleService} from "../../../@core/services/schule/schule.service";
import {PlzValidationHelperService} from "../../../@core/utils/forms/plz-validation-helper.service";
import {NgxSpinnerService} from "ngx-spinner";
import {ImageHelperService} from "../../../@core/utils/forms/image-helper.service";
import {createVormundAgeValidator, DateTimeHelperService} from "../../../@core/utils/forms/datetime-helper.service";
import {toTitlecase} from "../../../@core/utils/forms/string-helper";
import {ModuleConfigService} from "../../../@core/services/module-config/module-config.service";
import {SessionService} from "../../../@core/services/session/session.service";
import {TranslateService} from "@ngx-translate/core";
import {KeyString} from "../../../models/textbaustein/KeyString";
import {ValidationHelperService} from "../../../@core/utils/forms/validation-helper.service";

/**
 * @deprecated This component is deprecated. Use fahrkosten-detail-sb-true.
 */

@Component({
  selector: 'app-fahrkosten-detail-sb-false',
  templateUrl: './fahrkosten-detail-sb-false.component.html',
  styleUrls: ['./fahrkosten-detail-sb-false.component.scss']
})
export class FahrkostenDetailSbFalseComponent implements OnInit {

  //received from parent SBtrue/false splitter
  @Input() fahrkostenId;
  @Input() benutzer;
  @Input() benutzerRoles;
  @Input() fieldConfig;
  @Input() optionTyp;
  @Input() optionGeschlecht;
  @Input() optionSonderfall;
  @Input() optionWegtyp;
  @Input() optionBefoerderung;
  @Input() landList;
  @Input() pflichtFeldMarker;
  @Input() vormundOnOff;
  @Input() vormundAdresseAbweichendOnOff;
  @Input() schuleOnOff;
  @Input() berufskollegOnOff;
  @Input() schulwegOnOff;
  @Input() praktikumOnOff;
  @Input() beidesOnOff;
  @Input() pkwOnOff;
  @Input() mitfahrerOnOff;
  @Input() oepnvOnOff;
  @Input() kontoinhaberOnOff;
  @Input() sonderfallOnOff;
  @Input() sonderfallSonstOnOff;
  @Input() asylOnOff;
  @Input() fahrkostenFormFields;

  // for visibility and validation
  @Input() requiredFields: string[] = [];
  @Input() visibleFields: string[] = [];

  draftStatus = FahrkostenStatus.ENTWURF;

  //fahrkosten specific
  fahrkosten: FahrkostenDTO;
  fahrkostenForm: UntypedFormGroup;
  fahrkostenHistorie;
  readonly: boolean = true;
  readonlyTyp : boolean = undefined;

  schuelerDeleted : boolean = false;

  //reduzierte Statusunterscheidung für Antragsteller
  //alles was nicht entwurf oder abgeschlossen ist, ist "in Bearbeitung"
  isEntwurf: boolean = true;
  isInBearbeitung: boolean = false;
  isFinalized: boolean = false;

  //this blocks resetting of formValues during async loading of existing fahrkosten
  isInitializing: boolean = false;

  //visibility and validation
  fieldConfigFkkUniversal;
  isSubmitted: boolean = false;
  effectiveValidators: string[] = [];
  effectivelyVisible: string[] = [];
  formItemSubscriptions: Subscription[] = [];
  disableCheckboxes: string[] = [
    'isVormundadresseAbweichend','isKontoinhaberAbweichend','sonderfallEnabled',
    'sonderfallSchuleAusserhalb','sonderfallSchulungsmoeglichkeit','sonderfallFamilienheimfahrten',
    'bezugAsylbewerberleistungsgesetz','acceptTerms'
  ];
  isDeleteDraftButtonVisible: boolean = false;
  isUpdateEntwurfButtonVisible: boolean = false;
  isEinreichenButtonVisible: boolean = false;
  childrenValidity: Map<number,boolean> = new Map<number, boolean>();
  invalidControlsChildren : string = '';
  isAllChildrenValid: boolean = true;
  childrenResponses: number = 0;
  flipFlop: boolean = false;      // needed for changeDetection in children after isSubmitted becomes true

  cardsFkkUniversalOn: boolean = false;
  visibleFieldsFkkUniversal: string[] = [];
  requiredFieldsFkkUniversal: string[] = [];

  // picturefiles
  praktikumsvertrag: string;
  praktikumsvertragIsPDF: boolean = false;
  sonderfallNachweis: string;
  sonderfallNachweisIsPDF: boolean = false;
  bezugNachweis: string;
  bezugNachweisIsPDF: boolean = false;
  fahrkostenNachweis: string;
  fahrkostenNachweisIsPDF: boolean = false;

  //list specific to SBfalse
  schuleList = [];
  schuelerList = [];
  antragstellerList = [];

  //icons
  faInfo = faInfoCircle;
  faCheck = faCheckCircle;
  faNope = faTimesCircle;
  faPlus = faPlus;
  faCirclePlus = faCirclePlus;
  faClock = faClock;

  //calendar stuff
  today : string;
  minDate : string;
  maxDate : string;
  minDateErstattungszeitraumBis : string;
  minDatePraktikumEnddatum : string;
  maxDateVormundGeburtsdatum : string;
  minDateBirthday : string;

  allowedNachweisFormats : string = ImageHelperService.ALLOWED_NACHWEIS_FILE_FORMATS;

  postkorbReference: string;

  existsSessionStorageTextbausteine: boolean = false;

  constructor(
    private fahrkostenService: FahrkostenService,
    private schuelerService: SchuelerService,
    private customToastService: CustomToastService,
    private router: Router,
    private fb: UntypedFormBuilder,
    public  fieldConfigService: FieldConfigService,
    public moduleConfigService: ModuleConfigService,
    private modalService: NgbModal,
    private parent: FahrkostenDetailParentComponent,
    private sanitizer: DomSanitizer,
    public textbausteinService: TextbausteinService,
    private schuleService: SchuleService,
    public plzHelper: PlzValidationHelperService,
    private spinner: NgxSpinnerService,
    private imageHelper: ImageHelperService,
    private datetimeHelper: DateTimeHelperService,
    private sessionService: SessionService,
    private translateService: TranslateService,
    private validationHelper: ValidationHelperService
  ) { }

  ngOnInit(): void {
    this.spinner.show().catch(()=>{});

    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });

    this.loadFieldConfigForFkkUniversal();
    this.initRequiredFieldsFkkUniversal();

    this.minDate = (new Date(1900,0,0)).toISOString().slice(0, 10);
    this.minDateErstattungszeitraumBis = (new Date(1900,0,0)).toISOString().slice(0, 10);
    this.today   = new Date().toISOString().slice(0, 10);

    this.initListsForSBfalse();
    this.initEmptyForm();
    this.effectivelyVisible  = [...this.visibleFields];
    this.effectiveValidators = [...this.requiredFields];
    this.antragstellerList   = [...this.schuelerList,{id: -1, fullName: 'Mich selbst'}];

    this.createSubscriptions();
    this.setDateMinMax();

  }

  ngAfterViewInit(){
    this.spinner.hide().catch(()=>{});
  }

  setDateMinMax(){
    this.minDate = DateTimeHelperService.CALENDAR_MINIMUM;
    this.minDateBirthday = DateTimeHelperService.BIRTHDATE_MINIMUM;
    this.minDateErstattungszeitraumBis = DateTimeHelperService.CALENDAR_MINIMUM;
    this.today   = this.datetimeHelper.formattedToday();
    this.maxDate = DateTimeHelperService.CALENDAR_MAXIMUM;
    this.maxDateVormundGeburtsdatum = this.datetimeHelper.maxDateVormund();
  }

  loadFahrkosten(): void {
    // LOAD EXISTING ANTRAG
    if(!(!this.fahrkostenId)&&this.fahrkostenId!='') {
      // load fahrkosten
      this.fahrkostenService.getFahrkosten(this.fahrkostenId)
        .pipe(
          catchError(() => {
            this.customToastService.showError('Der Fahrkostenantrag konnte nicht geladen werden.');
            return EMPTY;
          })
        )
        .subscribe((data: FahrkostenDTO) => {

          this.isInitializing = true;


          this.fahrkosten = {...data};
          this.postkorbReference = this.fahrkosten.postkorbReference;

          //sort cards by befoerderungsart
          this.fahrkosten.fahrkostenKostenUniversalList.sort((a, b) => (a.befoerderung > b.befoerderung) ? 1 : -1);
          this.fahrkostenId = this.fahrkosten.id;

          // set status visible to user
          this.isEntwurf = this.fahrkosten.status === FahrkostenStatus.ENTWURF;
          this.isFinalized = this.fahrkosten.status === FahrkostenStatus.ABGESCHLOSSEN;
          this.isInBearbeitung = !(this.isEntwurf||this.isFinalized);
          this.readonly = this.fahrkosten.status != FahrkostenStatus.ENTWURF;
          this.schuelerDeleted = this.fahrkosten.schueler?.deleted || false;
          if(this.schuelerDeleted){
            this.customToastService.showWarning(this.readonly? "Schüler:in wurde gelöscht." :
              "Schüler:in wurde gelöscht. Sie müssen eine/n gültige/n Schüler:in auswählen, um den Antrag einreichen zu können.");
          }

          // dto -> form
          this.autoPatchFromDTO();
          this.fahrkostenForm.patchValue({schuelerId: this.fahrkosten.schueler?.id || -1});
          setTimeout(() => {
            this.parent.subtitle=this.adjustSubtitle();
          });
          this.updateEverything();

          // belege für Sonderfall etc.
          if(!(!this.fahrkosten.praktikumsvertragId))
            this.fahrkostenService.downloadPraktikumsvertrag(this.fahrkostenId)
              .pipe(catchError(() => {
                this.customToastService.showError("Praktikumsvertrag konnte nicht geladen werden.");
                return EMPTY;
              }))
              .subscribe(result => {
                this.praktikumsvertragIsPDF = result.image.indexOf("application/pdf")>-1;
                this.praktikumsvertrag = result.image;
              });
          if(!(!this.fahrkosten.sonderfallNachweisId))
            this.fahrkostenService.downloadSonderfallNachweis(this.fahrkostenId)
              .pipe(catchError(() => {
                this.customToastService.showError("Sonderfallnachweis konnte nicht geladen werden.");
                return EMPTY;
              }))
              .subscribe(result => {
                this.sonderfallNachweisIsPDF = result.image.indexOf("application/pdf")>-1;
                this.sonderfallNachweis = result.image;
              });
          if(!(!this.fahrkosten.bezugNachweisId))
            this.fahrkostenService.downloadBezugNachweis(this.fahrkostenId)
              .pipe(catchError(() => {
                this.customToastService.showError("Bezugnachweis konnte nicht geladen werden.");
                return EMPTY;
              }))
              .subscribe(result => {
                this.bezugNachweisIsPDF = result.image.indexOf("application/pdf")>-1;
                this.bezugNachweis = result.image;
              });
          if(!(!this.fahrkosten.fahrkostenNachweisId))
            this.fahrkostenService.downloadFahrkostenNachweis(this.fahrkostenId)
              .pipe(catchError(() => {
                this.customToastService.showError("Fahrkostennachweis konnte nicht geladen werden.");
                return EMPTY;
              }))
              .subscribe(result => {
                this.fahrkostenNachweisIsPDF = result.image.indexOf("application/pdf")>-1;
                this.fahrkostenNachweis = result.image;
              });

          this.isInitializing = false;

        }); // end load fahrkostenDTO

      // load history
      this.fahrkostenService.getHistory(this.fahrkostenId)
        .pipe(
          catchError(() => {
            this.customToastService.showWarning("Historie nicht gefunden.");
            return EMPTY;
          })
        )
        .subscribe(data => {
          this.fahrkostenHistorie = data;
        });

    } else { // fahrkosten is new
      this.isEntwurf = true;
      this.readonly = false;
      this.updateEverything();
      setTimeout(() => {
        this.parent.subtitle=this.adjustSubtitle();
      });
      this.updateEverything();
    }

} // end ngOnInit

  createSubscriptions(){
    /*
      SonarLint marks startWith as deprecated, but it's only deprecated if used with a scheduler
      startWith(null) and startWith(undefined) are ok
     */
    this.formItemSubscriptions.push(
      this.fahrkostenForm.get('schuelerId').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.checkIfVorgaengerExists();
          }
        }),
      this.fahrkostenForm.get('geburtsdatum').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.vormundChanged();
          }
        }),
      this.fahrkostenForm.get('isVormundadresseAbweichend').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.isVormundadresseAbweichendChanged();
          }
        }),
      this.fahrkostenForm.get('schule').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.schuleChanged();
          }
        }),
      this.fahrkostenForm.get('isBerufskolleg').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.isBerufskollegChanged();
          }
        }),
      this.fahrkostenForm.get('wegtyp').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.wegtypChanged();
          }
        }),
      this.fahrkostenForm.get('isKontoinhaberAbweichend').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.isKontoinhaberAbweichendChanged();
          }
        }),
      this.fahrkostenForm.get('befoerderung').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.befoerderungChanged();
          }
        }),
      this.fahrkostenForm.get('sonderfall').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.sonderfallTypChange();
          }
        }),
      this.fahrkostenForm.get('sonderfallSchulungsmoeglichkeit').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.sonderfallAusserhalbNRWChange();
          }
        }),
      this.fahrkostenForm.get('sonderfallSchuleAusserhalb').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.sonderfallAusserhalbNRWChange();
          }
        }),
      this.fahrkostenForm.get('sonderfallFamilienheimfahrten').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.sonderfallAusserhalbNRWChange();
          }
        }),
      this.fahrkostenForm.get('bezugAsylbewerberleistungsgesetz').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next == null || next!==prev){
            this.asylChanged();
          }
        }),
      this.getFormItem('land')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('plz');
      }),
      this.fahrkostenForm.get('erstattungszeitraumVon').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.erstattungszeitraumChange();
          }
        }),
      this.fahrkostenForm.get('erstattungszeitraumBis').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.erstattungszeitraumChange();
          }
        }),
      this.fahrkostenForm.get('praktikumStartdatum').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.praktikumDatumChange();
          }
        }),
      this.fahrkostenForm.get('praktikumEnddatum').valueChanges
        .pipe(startWith(null),pairwise())
        .subscribe(([prev, next]: [any, any]) => {
          if(next!==prev){
            this.praktikumDatumChange();
          }
        }),
      this.getFormItem('land')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('plz');
      }),
      this.getFormItem('vormundLand')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('vormundPlz');
      }),
      this.getFormItem('praktikumLand')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('praktikumPlz');
      }),
      this.getFormItem('kontoinhaberLand')?.valueChanges.subscribe(() => {
        this.setPLZValidatorForControl('kontoinhaberPlz');
      })
    );

  }

  updateEverything() {
    this.setButtonVisibility();
    this.vormundChanged();
    this.wegtypChanged();
    this.schuleChanged();
    this.befoerderungChanged();
    this.isKontoinhaberAbweichendChanged();
    this.sonderfallTypChange();
    this.sonderfallAusserhalbNRWChange();
    this.asylChanged();
    this.erstattungszeitraumChange();
    this.praktikumDatumChange();
    this.disableCheckboxesFunc();
  }

  addFahrkostenKostenUniversalCard(){
    let listTemp = this.getFormItem('fahrkostenKostenUniversalList')?.value || [];
    let fkkUniversal = {
      id: undefined, fahrkostenId: this.fahrkostenId || undefined,
      kostenId: (listTemp?.length+1) || 1,
      befoerderung: undefined, jahr: undefined, monat: undefined,
      belegArt: undefined, belegAnzahl: undefined, einzelpreis: undefined, gesamtpreis: undefined,
      korrekturAnzahl: undefined, korrekturEinzelpreis: undefined, korrekturGesamtpreis: undefined,
      fahrzeugtyp: undefined, kennzeichen: undefined, gefahreneTage: undefined, gruende: undefined,
      mitfahrerBei: undefined, mitgenommen: undefined,
      datum: undefined, datumConfirmed: undefined,
      gefahreneTageConfirmed: undefined,
      erstattungFuerPkwnutzung: undefined,
      dateiFahrkostenList: []
    };

    if(!listTemp)
      listTemp = [fkkUniversal];
    else
      listTemp.unshift(fkkUniversal);
    this.fahrkostenForm.patchValue({fahrkostenKostenUniversalList: listTemp});
  }

  getArrayIndexOf(fkkUniversal: FahrkostenKostenUniversalDTO){
    return this.getFormItem('fahrkostenKostenUniversalList').value.indexOf(fkkUniversal);
  }

  removeFahrkostenKostenUniversalCard(arrayIndex:number){
    let currentList = this.getFormItem('fahrkostenKostenUniversalList').value;
    currentList.splice(arrayIndex,1);
    this.fahrkostenForm.patchValue({fahrkostenKostenUniversalList: currentList});
  }

  disableCheckboxesFunc(){
    if(this.readonly === true && !(!this.fahrkostenForm)){
      this.disableCheckboxes.forEach(cb=>this.fahrkostenForm.get(cb)?.disable());
    } else if(this.readonly !== true && !(!this.fahrkostenForm)) {
      this.disableCheckboxes.forEach(cb=>this.fahrkostenForm.get(cb)?.enable());
    }
  }

  autoPatchFromDTO(){
    if(!(!this.fahrkosten)&&!(!this.fahrkostenForm)){
      let notPatched: string[] = [];
      const controls = this.fahrkostenForm.controls;
      for (const varName in controls) {
        try{
          this.fahrkostenForm.patchValue({[varName] : this.fahrkosten[varName]});
        } catch (e) {
          notPatched.push(varName);
        }
      }
      if(notPatched.length>0)
        console.log('Failed patches DTO->form in vars:',notPatched);
    }
  }

  //DECISION EINREICHEN
  showDecisionModal(targetStatus: string, saveMsg: string, goToPath: string){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.textbausteinService.printTextbausteinByKey( KeyString.FAHRKOSTEN_EINREICHEN_MODAL_TITEL, this.translateService.instant('MODAL.ANTRAG_EINREICHEN_MODAL_TITEL'));
    modalRef.componentInstance.text = this.textbausteinService.printTextbausteinByKey( KeyString.FAHRKOSTEN_EINREICHEN_MODAL_TEXT,this.translateService.instant('MODAL.ANTRAG_EINREICHEN_MODAL_TEXT'));
    modalRef.componentInstance.onYes = () => {
      this.spinner.show().catch(()=>{});
      if(!this.fahrkostenId){
        this.createFahrkosten(targetStatus, saveMsg, goToPath);
      } else {
        this.updateFahrkosten(targetStatus, saveMsg, goToPath);
      }
    };
    modalRef.componentInstance.onNo = () => this.spinner.hide();
  }

  setChildrenValidity(event:any){
    this.childrenValidity.set(event.kostenId,event.isValid);
    if(!event.isValid) {
      this.isAllChildrenValid = false;
      if(!!event.invalidControls && event.invalidControls.length>0){
        event.invalidControls.forEach( iC => {
            if (!this.invalidControlsChildren.includes(iC))
              this.invalidControlsChildren += (iC + ', ')
          }
        )
      }
    } else {
      this.checkChildrenValidity();
    }
    if(this.invalidControlsChildren.length>2)
      this.invalidControlsChildren = this.invalidControlsChildren.substring(0,this.invalidControlsChildren.length-2)
  }

  checkChildrenValidity() {
    this.isAllChildrenValid = !(Array.from(this.childrenValidity.values()).some(val => val === false));
  }

  waitForChildren() : Promise<any> {
    return new Promise<any>((resolve)=>{
      const childrenNum = !(!this.getFormItem('fahrkostenKostenUniversalList')?.value) ? Array.from(this.getFormItem('fahrkostenKostenUniversalList')?.value).length : 0;
      let interval = setInterval(() => {
        if (this.isSubmitted && (this.childrenResponses!==childrenNum))
          return;
        clearInterval(interval);
        resolve('done');
      }, 100);
    });
  }

  // SAVE
  saveOrUpdate(targetStatus: string, saveMsg: string, goToPath: string){
    this.spinner.show().catch(()=>{});
    this.patchAntragsteller();
    if(targetStatus == 'EINGEREICHT'){
      this.childrenResponses = 0;
      this.childrenValidity.clear();
      this.invalidControlsChildren = '';
      this.isAllChildrenValid = true;
      this.isSubmitted = true;
      this.flipFlop = !this.flipFlop;   // flipflop triggers children to report their validity to parent
      this.waitForChildren().then(()=>{
        this.spinner.hide().catch(()=>{});
        this.applyValidators().then(()=>{
          if ( !this.fahrkostenForm.valid || !this.isAllChildrenValid ) {
            const nonValidFK = this.getNotValid();
            if(nonValidFK.length>0)
              this.customToastService.showError('Es sind nicht alle Felder korrekt ausgefüllt. Es fehlt: ' + nonValidFK );
            if(this.invalidControlsChildren.length>0){
              this.customToastService.showError('In mind. einem Fahrkostennachweis fehlt: '+this.invalidControlsChildren)
            }
          } else {
            this.showDecisionModal(targetStatus,saveMsg,goToPath);
          }
        }).catch(()=>{});
      }).catch(()=>{});
    } else if(targetStatus == 'ENTWURF') {
      this.isSubmitted = false;
      this.clearAllValidators();
      if(!this.fahrkostenId){
        this.createFahrkosten(targetStatus, saveMsg, goToPath);
      } else {
        this.updateFahrkosten(targetStatus, saveMsg, goToPath);
      }
    }
  }

  createFahrkosten(targetStatus: string, saveMsg: string, goToPath: string){
    this.saveCreate(targetStatus).then((result)=>{
      if(result){
        if(goToPath=='fahrkosten-detail') goToPath += '/'+this.fahrkosten.id;
        this.router.navigate(['pages/'+goToPath]).then(() => {
          this.customToastService.showSuccess(saveMsg);
        }).catch(()=>{});
      } else {
        this.spinner.hide().catch(()=>{});
      }
    }).catch(err=>{
      console.log(err);
      this.spinner.hide().catch(()=>{});
    });
  }

  saveCreate(targetStatus): Promise<boolean> {
    return new Promise<boolean>((resolve)=>{
      let fahrkostenCreateDTOtemp : FahrkostenCreateDTO = {} as FahrkostenCreateDTO;
      this.autoCreateDTO(fahrkostenCreateDTOtemp);
      fahrkostenCreateDTOtemp.status = FahrkostenStatus[targetStatus];
      if(this.getFormItem('schuelerId').value!==-1){  // -1 == mich selbst
        fahrkostenCreateDTOtemp.schuelerId = this.getFormItem('schuelerId').value;
      } else {
        fahrkostenCreateDTOtemp.schuelerId = undefined;
      }

      let praktikumsvertrag: File = undefined;
      if (!(!this.getFormItem('praktikumsvertrag')) && !(!this.getFormItem('praktikumsvertragFile')?.value)) {
        praktikumsvertrag = this.getFormItem('praktikumsvertragFile').value;
      }
      let sonderfallNachweis: File = undefined;
      if (!(!this.getFormItem('sonderfallNachweis')) && !(!this.getFormItem('sonderfallNachweisFile')?.value)) {
        sonderfallNachweis = this.getFormItem('sonderfallNachweisFile').value;
      }
      let bezugNachweis: File = undefined;
      if (!(!this.getFormItem('bezugNachweis')) && !(!this.getFormItem('bezugNachweisFile')?.value)) {
        bezugNachweis = this.getFormItem('bezugNachweisFile').value;
      }
      let fahrkostenNachweis: File = undefined;
      if (!(!this.getFormItem('fahrkostenNachweis')) && !(!this.getFormItem('fahrkostenNachweisFile')?.value)) {
        fahrkostenNachweis = this.getFormItem('fahrkostenNachweisFile').value;
      }

      this.fahrkostenService.createFahrkosten(fahrkostenCreateDTOtemp, praktikumsvertrag, sonderfallNachweis, bezugNachweis, fahrkostenNachweis)
        .pipe(
          catchError(() => {
            this.customToastService.showError('Der Fahrkostenantrag konnte nicht gespeichert werden.');
            resolve(false);
            return EMPTY;
          })
        )
        .subscribe((data: FahrkostenDTO) => {
          this.fahrkosten = {...data};
          this.fahrkostenId = data.id;
          resolve(true);
        });
    });
  }

  /**
   * Auto create DTO from form values
   * @param targetDTO - the DTO to be filled
   */
  autoCreateDTO(targetDTO: any){
    let failed: string[] = [];
    this.fahrkostenFormFields.forEach((varname) => {
      let formValue = null;
      if(this.getFormItem(varname)){
        formValue = this.getFormItem(varname)?.value;
      } else {
        failed.push(varname);
      }
      targetDTO[varname] = formValue;
    });
    if(failed.length > 0)
      console.log('Failed form -> create dto at var(s): ',failed);
  }

  // UPDATE
  updateFahrkosten(targetStatus: string, saveMsg: string, goToPath: string){
    this.saveUpdate(targetStatus).then((result)=>{
      if(result){
        if(goToPath=='fahrkosten-detail') goToPath += '/'+this.fahrkosten.id;
        this.router.navigate(['pages/'+goToPath]).then(() => {
          this.customToastService.showSuccess(saveMsg);
        }).catch(()=>{});
      } else {
        this.spinner.hide().catch(()=>{});
      }
    }).catch(()=>{
      this.spinner.hide().catch(()=>{});
    });
  }

  saveUpdate(targetStatus): Promise<boolean> {
    return new Promise<boolean>((resolve)=>{
      let fahrkostenUpdateDTOtemp : FahrkostenUpdateDTO = {} as FahrkostenUpdateDTO;
      this.autoCreateDTO(fahrkostenUpdateDTOtemp);
      fahrkostenUpdateDTOtemp.status = FahrkostenStatus[targetStatus];
      if(this.getFormItem('schuelerId').value!==-1){  // -1 == mich selbst
        fahrkostenUpdateDTOtemp.schuelerId = this.getFormItem('schuelerId').value;
      } else {
        fahrkostenUpdateDTOtemp.schuelerId = null;
      }

      let praktikumsvertrag: File = undefined;
      if (!(!this.getFormItem('praktikumsvertrag')) && !(!this.getFormItem('praktikumsvertragFile')?.value)) {
        praktikumsvertrag = this.getFormItem('praktikumsvertragFile').value;
      }
      let sonderfallNachweis: File = undefined;
      if (!(!this.getFormItem('sonderfallNachweis')) && !(!this.getFormItem('sonderfallNachweisFile')?.value)) {
        sonderfallNachweis = this.getFormItem('sonderfallNachweisFile').value;
      }
      let bezugNachweis: File = undefined;
      if (!(!this.getFormItem('bezugNachweis')) && !(!this.getFormItem('bezugNachweisFile')?.value)) {
        bezugNachweis = this.getFormItem('bezugNachweisFile').value;
      }
      let fahrkostenNachweis: File = undefined;
      if (!(!this.getFormItem('fahrkostenNachweis')) && !(!this.getFormItem('fahrkostenNachweisFile')?.value)) {
        fahrkostenNachweis = this.getFormItem('fahrkostenNachweisFile').value;
      }

      this.fahrkostenService.updateFahrkosten(''+this.fahrkosten.id,fahrkostenUpdateDTOtemp, praktikumsvertrag, sonderfallNachweis, bezugNachweis, fahrkostenNachweis)
        .pipe(
          catchError(() => {
            this.customToastService.showError('Der Fahrkostenantrag konnte nicht gespeichert werden.');
            resolve(false);
            return EMPTY;
          })
        )
        .subscribe((data: FahrkostenDTO) => {
          this.fahrkosten = {...data};
          resolve(true);
        });
    });
  }

  // DELETE
  deleteDraft(){
    const modalRef = this.modalService.open(ConfirmComponent);
    modalRef.componentInstance.title = this.textbausteinService.printTextbausteinByKey( KeyString.ENTWURF_LOESCHEN_MODAL_TITEL,
      this.translateService.instant('MODAL.ENTWURF_LOESCHEN_MODAL_TITEL'));
    modalRef.componentInstance.text = this.textbausteinService.printTextbausteinByKey( KeyString.ENTWURF_LOESCHEN_MODAL_TEXT,
      this.translateService.instant('MODAL.ENTWURF_LOESCHEN_MODAL_TEXT'));
    modalRef.componentInstance.onYes = () => {
      this.fahrkostenService.deleteFahrkosten(parseInt(this.fahrkostenId))
        .pipe(
          catchError(() => {
            this.customToastService.showError(this.translateService.instant('MODAL.ENTWURF_LOESCHEN_MODAL_ERROR'));
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.router.navigate(['/pages/fahrkosten']).then(() => {
            this.customToastService.showSuccess(this.translateService.instant('MODAL.ENTWURF_LOESCHEN_MODAL_SUCCESS'));
          }).catch(()=>{});
        });
    };
  }

  deleteNachweis(currentTarget: string){
    if( this.readonly || this.isInitializing || !this.fahrkostenForm )
      return;
    switch (currentTarget) {
      case 'sonderfallNachweis': this.sonderfallNachweis = null; break;
      case 'praktikumsvertrag': this.praktikumsvertrag = null; break;
      case 'bezugNachweis': this.bezugNachweis = null; break;
      case 'fahrkostenNachweis': this.fahrkostenNachweis = null; break;
      default: return;
    }
    this.fahrkostenForm.get(currentTarget).patchValue(null,{emitEvent: false, onlySelf: true});
    this.fahrkostenForm.get(currentTarget+'File').patchValue(undefined,{emitEvent: false, onlySelf: true});
    this.fahrkostenForm.get(currentTarget+'Id').patchValue(null,{emitEvent: false, onlySelf: true});
    this.fahrkostenForm.get(currentTarget).updateValueAndValidity();
  }

  clearAllValidators(){
    const controls = this.fahrkostenForm.controls;
    for (const name in controls) {
      controls[name].clearAsyncValidators();
      controls[name].clearValidators();
      if( !((name === 'iban') || (name === 'vormundGeburtsdatum')) )
        controls[name].setErrors(null);
    }
  }

  applyValidators(): Promise<any> {
    return new Promise ((resolve) => {
      this.clearAllValidators();
      this.effectiveValidators.forEach((varName)=>{
        if(varName.toLowerCase().includes('email')) {
          this.getFormItem(varName)?.setValidators([Validators.required, Validators.email]);
        } else if(varName.toLowerCase().includes('plz')) {
          this.setPLZValidatorForControl(varName);
        } else if(varName.toLowerCase().includes('iban')) {
          this.getFormItem(varName)?.setValidators([Validators.required,ValidatorService.validateIban]);
        } else if (varName === 'vormundGeburtsdatum') {
          this.getFormItem(varName)?.setValidators([Validators.required,createVormundAgeValidator()])
        } else if(varName === 'acceptTerms') {
          this.getFormItem(varName)?.setValidators([Validators.requiredTrue]);
        } else if (varName === 'praktikumsvertrag' && !(!this.praktikumsvertrag)) {
          this.getFormItem(varName)?.clearValidators();
        } else if (varName === 'sonderfallNachweis' && !(!this.sonderfallNachweis)) {
          this.getFormItem(varName)?.clearValidators();
        } else if (varName === 'bezugNachweis' && !(!this.bezugNachweis)) {
          this.getFormItem(varName)?.clearValidators();
        } else if (varName === 'fahrkostenNachweis' && !(!this.fahrkostenNachweis)) {
          this.getFormItem(varName)?.clearValidators();
        } else {
          this.getFormItem(varName)?.setValidators([Validators.required]);
        }
        this.getFormItem(varName)?.updateValueAndValidity({onlySelf: true, emitEvent: false});

        //this is a trick to make validators register values that were already in field,
        //when .updateValueAndValidity() was called
        //or values that have been loaded programmatically
        //also needed when someone tries to save an empty form,
        //for some reason markAsTouched, markAsDirty, markAllAsTouched all have no effect
        if(this.getFormItem(varName)?.disabled){// if off, turn on and off again
          this.getFormItem(varName)?.enable(); this.getFormItem(varName)?.disable();
        } else { //if on, turn off and on again
          this.getFormItem(varName)?.disable(); this.getFormItem(varName)?.enable();
        }
      });
      resolve('hurra');
    });
  }

  // VISIBILITY
  schuleChanged(){
    // reset lists and values that depend on schule
    if( !this.readonly && !this.isInitializing && !!this.fahrkostenForm ) {
      this.fahrkostenForm.patchValue({
        isBerufskolleg: null,
        klasse: null,
        bildungsgang: null,
        fachbereich: null,
        bildungsgangdatum: null,
        schuljahr: null
      });
    }
    const current = this.getFormItem('schule')?.value;
    if(!(!current)) {
      this.schuleList.forEach(schule=>{
        if(schule.name==current){
          this.fahrkostenForm.patchValue({isBerufskolleg: schule.berufskolleg});
        }
      });
      this.isBerufskollegChanged(); // explicit call due to async
    } else {
      this.isBerufskollegChanged(); // explicit call due to async
    }
  }

  isBerufskollegChanged(){
    let add, remove;
    const isB = this.getFormItem('isBerufskolleg')?.value;
    if(isB===true){
      add = this.berufskollegOnOff;
      remove = this.schuleOnOff;
    } else {  // undefined or null value
      add = this.schuleOnOff;
      remove = this.berufskollegOnOff;
    }
    this.updateEffectivelyVisible(add,remove);
  }

  wegtypChanged(){
    let add, remove;
    const wT = this.getFormItem('wegtyp')?.value;
    switch(wT){
      case 'SCHULWEG': {
        add = this.schulwegOnOff;
        remove = this.praktikumOnOff.concat(this.beidesOnOff);
        break;
      }
      case 'PRAKTIKUMSWEG': {
        add = this.praktikumOnOff;
        remove = this.beidesOnOff.concat(this.schulwegOnOff);
        break;
      }
      case 'BEIDES': {
        add = this.schulwegOnOff.concat(this.praktikumOnOff).concat(this.beidesOnOff);
        remove = [];
        break;
      }
      default: {
        add = [];
        remove = this.beidesOnOff.concat(this.schulwegOnOff).concat(this.praktikumOnOff);
        break;
      }
    }
    if(remove.includes('praktikumsvertrag'))
      this.deleteNachweis('praktikumsvertrag');
    this.updateEffectivelyVisible(add,remove);
  }

  arrayContainsAnyFrom(array: string[], from: string[]) : boolean {
    return array.some((r) => from.includes(r));
  }

  vormundChanged(){
    if(!this.readonly)
      this.resetFormValues(['isVormundadresseAbweichend']);
    const underage: boolean = this.datetimeHelper.isYoungerThan(this.getFormItem('geburtsdatum')?.value,18);
    this.fahrkostenForm?.patchValue({isUnderAge: underage});
    let add, remove;
    if(underage){
      add = this.vormundOnOff;
      remove = ['emailSchueler','telefonnummer'];
    } else {
      add = ['emailSchueler','telefonnummer'];
      remove = this.vormundOnOff;
    }
    this.isVormundadresseAbweichendChanged();
    this.updateEffectivelyVisible(add,remove);
  }

  isVormundadresseAbweichendChanged(){
    let add, remove;
    if((this.getFormItem('isVormundadresseAbweichend')?.value)){
      add = this.vormundAdresseAbweichendOnOff;
      remove = [];
    } else {
      add = [];
      remove = this.vormundAdresseAbweichendOnOff;
    }
    this.updateEffectivelyVisible(add,remove);
  }

  befoerderungChanged(){
    let add, remove;
    switch(this.getFormItem('befoerderung')?.value){
      case 'PKW': {
        add=this.pkwOnOff;
        remove=this.mitfahrerOnOff.concat(this.oepnvOnOff);
        break;
      }
      case 'MITFAHRER': {
        add=this.mitfahrerOnOff;
        remove=this.pkwOnOff.concat(this.oepnvOnOff);
        break;
      }
      case 'OEPNV': {
        add=this.oepnvOnOff;
        remove=this.mitfahrerOnOff.concat(this.pkwOnOff);
        break;
      }
      default: {
        add=[];
        remove=this.mitfahrerOnOff.concat(this.pkwOnOff).concat(this.oepnvOnOff);
      }
    }
    this.updateEffectivelyVisible(add,remove);
  }

  isKontoinhaberAbweichendChanged(){
    let add, remove;
    if((this.getFormItem('isKontoinhaberAbweichend')?.value)===true){
      add=this.kontoinhaberOnOff;
      remove=[];
    } else {
      add=[];
      remove=this.kontoinhaberOnOff;
    }
    this.updateEffectivelyVisible(add,remove);
  }

  sonderfallTypChange(){
    let add, remove;
    if (!this.getFormItem('sonderfall')) return;
    if (this.getFormItem('sonderfall')?.value) {
      if (this.getFormItem('sonderfall')?.value === 'SONSTIGES'){
        add = this.sonderfallOnOff.concat(this.sonderfallSonstOnOff);
        remove = [];
      } else {
        add = this.sonderfallOnOff;
        remove = this.sonderfallSonstOnOff;
      }
    } else {
      if (this.getFormItem('sonderfallSchulungsmoeglichkeit')?.value
        || this.getFormItem('sonderfallSchuleAusserhalb')?.value
        || this.getFormItem('sonderfallFamilienheimfahrten')?.value) {
        add = [];
        remove = this.sonderfallSonstOnOff;
      } else {
        add = [];
        remove = this.sonderfallOnOff.concat(this.sonderfallSonstOnOff);
      }
    }
    if(remove.includes('sonderfallNachweis'))
      this.deleteNachweis('sonderfallNachweis');
    this.updateEffectivelyVisible(add,remove);
  }

  sonderfallAusserhalbNRWChange(){
    let add, remove;
    if (this.getFormItem('sonderfallSchulungsmoeglichkeit')?.value
      || this.getFormItem('sonderfallSchuleAusserhalb')?.value
      || this.getFormItem('sonderfallFamilienheimfahrten')?.value) {
      add = this.sonderfallOnOff;
      remove = [];
    } else {
      if (this.getFormItem('sonderfall')?.value){
        add = [];
        remove = [];
      } else {
        add = [];
        remove = this.sonderfallOnOff;
      }
    }
    this.updateEffectivelyVisible(add,remove);
  }

  asylChanged(){
    let add, remove;
    if(this.getFormItem('bezugAsylbewerberleistungsgesetz')?.value===true){
      add=this.asylOnOff;
      remove=[];
    } else {
      add=[];
      remove=this.asylOnOff;
    }
    if(remove.includes('bezugNachweis'))
      this.deleteNachweis('bezugNachweis');
    this.updateEffectivelyVisible(add,remove);
  }

  erstattungszeitraumChange() {
    if (!(!this.getFormItem('erstattungszeitraumVon'))) {
      this.minDateErstattungszeitraumBis = this.getFormItem('erstattungszeitraumVon')?.value;
      if (!(!this.getFormItem('erstattungszeitraumBis'))
        && this.getFormItem('erstattungszeitraumBis')?.value < this.getFormItem('erstattungszeitraumVon')?.value) {
        this.getFormItem('erstattungszeitraumBis')?.patchValue(this.getFormItem('erstattungszeitraumVon')?.value);
      }
    }
  }

  praktikumDatumChange() {
    if (!!this.getFormItem('praktikumStartdatum')) {
      this.minDatePraktikumEnddatum = this.getFormItem('praktikumStartdatum')?.value;
      if (!(!this.getFormItem('praktikumEnddatum'))
        && this.getFormItem('praktikumEnddatum')?.value < this.getFormItem('praktikumStartdatum')?.value) {
        this.getFormItem('praktikumEnddatum')?.patchValue(this.getFormItem('praktikumStartdatum')?.value);
      }
    }
  }

  //EFFECTIVE VALIDATION
  updateEffectivelyVisible(add: string[], remove: string[]){
    if(!!this.effectivelyVisible)
      remove.forEach(varName => {
        if ( !add.includes(varName) ) {
          while(this.effectivelyVisible.includes(varName)){
            this.effectivelyVisible.splice(this.effectivelyVisible.indexOf(varName),1);
          }
          this.resetFormValues([varName]);
        }
      });
    add.forEach(varName => {
      if( !this.effectivelyVisible.includes(varName) && this.visibleFields.includes(varName) ){
        this.effectivelyVisible = [...this.effectivelyVisible,varName];
      }
    });
    this.updateEffectiveValidators();
  }

  updateEffectiveValidators(){
    if(this.effectivelyVisible.length > 0) {
      this.effectiveValidators = [];
      this.requiredFields.forEach(varName => {
        if (this.effectivelyVisible.includes(varName)) {
          this.effectiveValidators = [...this.effectiveValidators,varName];
        }
      });
    }
    if(this.isSubmitted===true)
      this.applyValidators().catch(()=>{});
    else {  // these validators always react on bad input
      if(this.effectivelyVisible?.includes('iban')) {
        this.getFormItem('iban').setValidators([ValidatorService.validateIban]);
        this.getFormItem('iban').updateValueAndValidity();
      }
      if(this.effectivelyVisible?.includes('vormundGeburtsdatum')) {
        this.getFormItem('vormundGeburtsdatum').setValidators([createVormundAgeValidator()]);
        this.getFormItem('vormundGeburtsdatum').updateValueAndValidity();
      }
      if (this.effectivelyVisible?.includes('vormundStrasse')) { // add validators for strasse and hausnummer
        this.getFormItem('vormundStrasse')?.setValidators(Validators.pattern(this.validationHelper.strasseHausnummerPattern));
        this.getFormItem('vormundStrasse')?.updateValueAndValidity();
      }
      if (this.effectivelyVisible?.includes('praktikumStrasse')) { // add validators for strasse and hausnummer
        this.getFormItem('praktikumStrasse')?.setValidators(Validators.pattern(this.validationHelper.strasseHausnummerPattern));
        this.getFormItem('praktikumStrasse')?.updateValueAndValidity();
      }
      if (this.effectivelyVisible?.includes('kontoinhaberStrasse')) { // add validators for strasse and hausnummer
        this.getFormItem('kontoinhaberStrasse')?.setValidators(Validators.pattern(this.validationHelper.strasseHausnummerPattern));
        this.getFormItem('kontoinhaberStrasse')?.updateValueAndValidity();
      }
    }
  }

  // FILE UPLOADS
  sanitize(url: string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  previewImg(src){
    const modalRef = this.modalService.open(PictureFileComponent);
    modalRef.componentInstance.image = src;
  }

  previewPDF(src){
    const modalRef = this.modalService.open(PictureFileComponent);
    modalRef.componentInstance.width = 500;
    modalRef.componentInstance.height = 600;
    modalRef.componentInstance.pdf = src;
  }

  handleNachweis({currentTarget}){
    if(currentTarget.files != null && currentTarget.files.length > 0) {
      let file : File = currentTarget.files[0];
      this.imageHelper.handleNachweisFile(file)
        .then((processed)=>{
          let fileFormControlName : string = undefined;
          switch (currentTarget.id){
            case 'input-praktikumsvertrag':
              this.praktikumsvertragIsPDF = processed.isPDF;
              this.praktikumsvertrag = processed.image;
              fileFormControlName = 'praktikumsvertragFile';
              break;
            case 'input-sonderfallNachweis':
              this.sonderfallNachweisIsPDF = processed.isPDF;
              this.sonderfallNachweis = processed.image;
              fileFormControlName = 'sonderfallNachweisFile';
              break;
            case 'input-bezugNachweis':
              this.bezugNachweisIsPDF = processed.isPDF;
              this.bezugNachweis = processed.image;
              fileFormControlName = 'bezugNachweisFile';
              break;
            case 'input-fahrkostenNachweis':
              this.fahrkostenNachweisIsPDF = processed.isPDF;
              this.fahrkostenNachweis = processed.image;
              fileFormControlName = 'fahrkostenNachweisFile';
              break;
            default:
              console.log('Error, formControlId not recognized.',currentTarget.id);
              break;
          }
          if(!!(processed.error)) {
            this.customToastService.showError(processed.error);
            this.getFormItem(fileFormControlName).patchValue(undefined);
            return;
          }
          this.getFormItem(fileFormControlName).patchValue(file);
        }).catch(()=>{});
    } else {
      this.deleteNachweis((currentTarget.id).split('input-')[1])
    }
  }

  addSchueler(){
    if(this.fahrkostenForm.dirty || this.fahrkostenForm.touched)
      this.saveOrUpdate(FahrkostenStatus.ENTWURF,
        "Der Fahrkostenantrag wurde als Entwurf gespeichert. Sie werden zur Schülerseite weitergeleitet.",
        'schueler-table');
    else
      this.router.navigate(['pages/schueler-table']).catch(()=>{})
  }

  initEmptyForm(){
    this.fahrkostenForm = this.fb.group({
      name: undefined,
      vorname: undefined,
      strasse: undefined,
      plz: undefined,
      ort: undefined,
      adresszusatz: undefined,
      geburtsdatum: undefined,
      isUnderAge: undefined,
      geschlecht: undefined,
      telefonnummer: undefined,
      email: undefined,
      emailSchueler: undefined,
      schule: undefined,
      isBerufskolleg: undefined,
      schuljahr: undefined,
      klasse: undefined,
      bildungsgang: undefined,
      bildungsgangdatum: undefined,
      fachbereich: undefined,
      unterrichtszeitVon: undefined,
      unterrichtszeitBis: undefined,
      praktikumStelle:  undefined,
      erstattungszeitraumVon:  undefined,
      erstattungszeitraumBis:  undefined,
      fehltage: undefined,
      kosten: undefined,
      befoerderung: undefined,
      kennzeichen: undefined,
      mitfahrerBei: undefined,
      entfernung: undefined,
      hinweiseAntragsteller: undefined,
      bemerkungSchule: undefined,
      bemerkungSchultraeger: undefined,
      entfernungKorrektur: undefined,
      eigenanteilP2Abs2: undefined,
      eigenanteilP2Abs3: undefined,
      verbleibendeKosten: undefined,
      gesamtErstattung: undefined,
      nichtErstattet: undefined,
      buchungsdatumDerErstattung: undefined,
      erstattungFuerBusfahrten: undefined,
      erstattungFuerBahnfahrten: undefined,
      erstattungFuerFahrradNutzung: undefined,
      erstattungFuerTaxifahrten: undefined,
      erstattungFuerPKWNutzung: undefined,
      erstattungFuerKFZNutzung: undefined,
      textbausteinId: undefined,
      nachrichtAnAntragsteller: undefined,

      //neu
      land: undefined,
      vormundName: undefined,
      vormundVorname: undefined,
      isVormundadresseAbweichend: undefined,
      vormundStrasse: undefined,
      vormundOrt: undefined,
      vormundPlz:  undefined,
      vormundAdresszusatz: undefined,
      vormundLand:  undefined,
      vormundGeburtsdatum:  undefined,
      vormundTelefonnummer:  undefined,
      vormundEmail:  undefined,
      vormundGeschlecht:  undefined,

      //erstattung
      typ: undefined,
      wegtyp: undefined,
      schultageJeWoche: undefined,
      praktikumHinweis:  undefined,
      praktikumStrasse:  undefined,
      praktikumOrt:  undefined,
      praktikumPlz:  undefined,
      praktikumLand:  undefined,
      praktikumAdresszusatz:  undefined,
      praktikumStartdatum:  undefined,
      praktikumEnddatum:  undefined,
      praktikumstageJeWoche: undefined,
      krankheitstage: undefined,

      //sonderfall
      sonderfallEnabled:  undefined,
      sonderfall:  undefined,
      sonderfallBegruendung:  undefined,
      sonderfallSchuleAusserhalb:  undefined,
      sonderfallSchulungsmoeglichkeit:  undefined,
      sonderfallFamilienheimfahrten:  undefined,
      bezugAsylbewerberleistungsgesetz: undefined,

      // files
      praktikumsvertrag: undefined,
      praktikumsvertragFile: undefined,
      praktikumsvertragId: undefined,
      sonderfallNachweis: undefined,
      sonderfallNachweisFile: undefined,
      sonderfallNachweisId: undefined,
      bezugNachweis: undefined,
      bezugNachweisFile: undefined,
      bezugNachweisId: undefined,

      // Konto
      iban: undefined,
      bic:  undefined,
      isKontoinhaberAbweichend:  undefined,
      kontoinhaberName: undefined,
      kontoinhaberVorname: undefined,
      kontoinhaberStrasse: undefined,
      kontoinhaberOrt: undefined,
      kontoinhaberPlz: undefined,
      kontoinhaberAdresszusatz: undefined,
      kontoinhaberLand: undefined,
      kontoinhaberGeburtsdatum: undefined,

      schuelerId: undefined,

      //antragsteller
      antragstellerName: undefined,
      antragstellerVorname: undefined,
      antragstellerStrasse: undefined,
      antragstellerPlz: undefined,
      antragstellerOrt: undefined,
      antragstellerLand: undefined,
      antragstellerEmail: undefined,

      //datenschutz
      acceptTerms: undefined,

      //children
      fahrkostenKostenUniversalList: undefined,

      //neu
      fahrkostenNachweis: undefined,
      fahrkostenNachweisFile: undefined,
      fahrkostenNachweisId: undefined,

    });
  }

  checkIfVorgaengerExists(){
    // method name is for congruence with antrag ticket,
    // but fahrkosten has no api to check for vorgaenger

    if( this.readonly || this.isInitializing || this.fahrkostenForm )
      return;

    //resetForm
    this.resetFormValues(['name','vorname','strasse','plz','ort',
      'land','adresszusatz','geburtsdatum','isUnderAge','email','telefonnummer',
      'geschlecht','schule','typ']);
    this.resetFormValidators(['name','vorname','strasse','plz','ort',
      'land','adresszusatz','geburtsdatum','isUnderAge','email','telefonnummer',
      'geschlecht','schule','typ']);

    const schuelerId = this.getFormItem('schuelerId')?.value;
    // -1: mich selbst

    if(!!schuelerId && schuelerId!=-1) {
      let schueler: SchuelerDTO = null;
      this.schuelerService.getSchueler(this.getFormItem('schuelerId')?.value).subscribe(result => {
        schueler = result;
        if (!!schueler) {
          let schuleOK = this.checkSchule(schueler.schule?.name);
          this.fahrkostenForm.patchValue({
            name: result.name || undefined,
            vorname: result.vorname || undefined,
            strasse: result.strasse || undefined,
            plz: result.plz || undefined,
            ort: result.ort || undefined,
            land: result.land || 'DEUTSCHLAND',
            adresszusatz: result.adresszusatz || undefined,
            geburtsdatum: result.geburtsdatum || undefined,
            email: this.benutzer.email || undefined,
            geschlecht: result.geschlecht || undefined,
            schule: schuleOK? result.schule?.name : undefined,
            typ: 'NEUANTRAG'
          });
          this.readonlyTyp = true;
          this.schuelerDeleted = result.deleted;
          this.setButtonVisibility();
        }
      });
    } else {
      //"mich selbst"
      this.fahrkostenForm.patchValue({
        name: this.benutzer.name || undefined,
        vorname: this.benutzer.vorname || undefined,
        strasse: this.benutzer.strasse || undefined,
        plz: this.benutzer.plz || undefined,
        ort: this.benutzer.ort || undefined,
        land: this.benutzer.land || 'DEUTSCHLAND',
        adresszusatz: this.benutzer.adresszusatz || undefined,
        geburtsdatum: this.benutzer.geburtsdatum || undefined,
        email: this.benutzer.email || undefined,
        geschlecht: this.benutzer.geschlecht || undefined,
        typ: 'NEUANTRAG'
      });
      this.readonlyTyp = true;
      this.schuelerDeleted = false;
      this.setButtonVisibility();
    }

    this.updateEverything();

  }

  checkSchule(schuleName: string):boolean{
    let found = false;
    if(schuleName==null||schuleName.length<1)
      return false;
    this.schuleList.forEach(schuli => {
      if(schuli.name==schuleName){
        found = true;
      }
    });
    if(!found)
      this.customToastService.showWarning("Die geladene Schule ist nicht in der Liste der zulässigen Schulen. "+
          "Sie müssen eine neue manuell auswählen.")
    return found;
  }

  patchAntragsteller(){
    // patch Antragsteller
    this.fahrkostenForm.patchValue({
      antragstellerName: this.benutzer.name || undefined,
      antragstellerVorname: this.benutzer.vorname || undefined,
      antragstellerStrasse: this.benutzer.strasse || undefined,
      antragstellerPlz: this.benutzer.plz || undefined,
      antragstellerOrt: this.benutzer.ort || undefined,
      antragstellerLand: this.benutzer.land || undefined,
      antragstellerEmail: this.benutzer.email || undefined
    });
  }

  statusVisibleToUser(){
    if(this.isEntwurf) return 'ENTWURF';
    if(this.isFinalized) return 'ABGESCHLOSSEN';
    if(this.isInBearbeitung) return 'IN_BEARBEITUNG';
    return '';
  }

  initListsForSBfalse() {
    this.schuleService.getSchulen(true)
      .pipe(catchError(() => {
        this.loadErrorMessage();
        return EMPTY;
      }))
      .subscribe(schulen => {
        this.schuleList = schulen;
        if (!schulen || schulen.length < 1) {
          this.loadErrorMessage();
        }
      });
    this.schuelerService.getSchuelerList()
      .pipe(catchError(() => {
        this.loadErrorMessage();
        return EMPTY;
      }))
      .subscribe(schueler => {
        schueler.forEach((gizmo) => {
          this.schuelerList = [...this.schuelerList, {id: gizmo.id, fullName: ""+gizmo.vorname+' '+gizmo.name}];
          this.antragstellerList = [...this.antragstellerList, {id: gizmo.id, fullName: ""+gizmo.vorname+' '+gizmo.name}];
        });
      });
  }

  loadErrorMessage(){
    this.customToastService.showError('Daten konnten nicht geladen werden. Stellen Sie sicher, dass Ihre Sitzung im Portal nicht abgelaufen ist.');
    this.router.navigate(['pages/fahrkosten-table']).catch(()=>{});
  }

  adjustSubtitle():string{
    if(this.readonly!==true)
      return this.textbausteinService.printTextbausteinByKey( KeyString.SUBTITLE_TEXT_FAHRKOSTEN,
        this.translateService.instant('TICKETDETAIL.SUBTITLE_TEXT_TICKET'))
    else
      return '';
  }

  getFormItem(s: string) {
    return this.fahrkostenForm?.get(s);
  }

  resetFormValues(varnames: string[]){
    if( this.readonly || this.isInitializing || !this.fahrkostenForm )
      return;
    varnames.forEach((varname:string) => {
      this.fahrkostenForm?.get(varname)?.patchValue(null);
    });
  }

  resetFormValidators(varnames: string[]){
    varnames.forEach((varname:string) => {
      if(!(!this.getFormItem(varname))){
        this.getFormItem(varname).clearValidators();
        this.getFormItem(varname).clearAsyncValidators();
        this.getFormItem(varname).setErrors(null);
        this.getFormItem(varname).updateValueAndValidity();
      }
    });
  }

  getNotValid() : string{
    let notValid: string = '';
    const controls = this.fahrkostenForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        let temp = document.getElementById('sfklabel-'+name)
        notValid += ''+ (
          temp?.innerHTML.replace(''+this.pflichtFeldMarker,'').trim()
          || this.fieldConfigService.getFieldLabel(name, this.fieldConfig, toTitlecase(name))) +', ';
      }
    }
    return notValid.substring(0, notValid.length-2);  // remove last comma
  }

  ngOnChanges(changes: SimpleChanges){
     if(changes.visibleFields){
       // visible fields / fieldConfig are not loaded fast enough
       // which is why loading of Fahrkosten happens too soon and form doesn't work correctly
       // this is why the loading of Fahrkosten happens here,
       // after changeDetection has registered the incoming fieldConfig / visibleFields
       this.effectivelyVisible = [...this.visibleFields];
       this.effectiveValidators = [...this.requiredFields];
       this.loadFahrkosten();
       this.setButtonVisibility();
       this.cardsFkkUniversalOn = this.moduleConfigService.isModuleEnabled('fahrkostenKostenUniversal');

     }
  }

  setButtonVisibility(){
    if( !this.readonly ){
      this.isEinreichenButtonVisible = true;
      this.isUpdateEntwurfButtonVisible = true;
      if(!(!this.fahrkostenId)){
        this.isDeleteDraftButtonVisible = true;
      }
    } else {
      this.isDeleteDraftButtonVisible = false;
      this.isUpdateEntwurfButtonVisible = false;
      this.isEinreichenButtonVisible = false;
    }
  }

  ngOnDestroy(){
    this.formItemSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.spinner.hide().catch(()=>{});
  }

  // CONFIG for fahrkostenKostenUniversal
  loadFieldConfigForFkkUniversal(){
    this.cardsFkkUniversalOn = this.cardsFkkUniversalOn = this.moduleConfigService.isModuleEnabled('fahrkostenKostenUniversal');
    if(this.cardsFkkUniversalOn===true){
      this.fieldConfigFkkUniversal = this.fieldConfigService.getFieldConfigFromSessionStorage(['fahrkostenKostenUniversal']);
      if(!this.fieldConfigFkkUniversal || this.fieldConfigFkkUniversal.length <1){
        console.log('No fieldConfig for FahrkostenKostenUniversal found: setting default values');
        this.setFieldConfigDefaultForFkkUniversal(true);
      }
    }
  }

  setFieldConfigDefaultForFkkUniversal(onOff: boolean) {
    this.fieldConfigFkkUniversal = [
      {field: 'gefahreneTage',  visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'belegArt',       visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'belegAnzahl',    visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'einzelpreis',    visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'gesamtpreis',    visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'fahrzeugtyp',    visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'kennzeichen',    visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'gruende',        visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'mitfahrerBei',   visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'mitgenommen',    visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'belegUpload',    visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'hasSchuelermitnahme', visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'mitfahrer1',     visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'mitfahrer2',     visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'mitfahrer3',     visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'mitfahrer4',     visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
      {field: 'mitfahrer5',     visible: onOff, required: false, label: null, module: 'fahrkostenKostenUniversal'},
    ];
  }

  fahrkostenKostenUniversalFormFields : string[] = ['befoerderung','jahr','monat','gefahreneTage','belegArt','belegAnzahl',
    'einzelpreis','gesamtpreis','fahrzeugtyp','kennzeichen','gruende','mitfahrerBei','mitgenommen','belegUpload','hasSchuelermitnahme',
    'mitfahrer1','mitfahrer2','mitfahrer3','mitfahrer4','mitfahrer5'];
  fahrkostenKostenUniversalNeverValidate  : string[] = ['datum','hasSchuelermitnahme'];
  fahrkostenKostenUniversalAlwaysValidate : string[] = ['befoerderung','jahr','monat','gefahreneTage','belegAnzahl','einzelpreis','fahrzeugtyp'];

  initRequiredFieldsFkkUniversal(){
    let allFormFields = this.fahrkostenKostenUniversalFormFields;
    allFormFields.forEach((varName) => {
      let field = this.fieldConfigService.getFieldFromFieldConfig(varName, this.fieldConfigFkkUniversal);
      if(!(field)) {
        field = {field: varName, visible: true, required: false, label: null, module: 'fahrkostenKostenUniversal'};
      }
      if(field.visible){
        this.visibleFieldsFkkUniversal = [...this.visibleFieldsFkkUniversal,varName];
        if(!this.fahrkostenKostenUniversalNeverValidate.includes(varName) &&
          (this.fahrkostenKostenUniversalAlwaysValidate.includes(varName) || field.required)){
          this.requiredFieldsFkkUniversal = [...this.requiredFieldsFkkUniversal,varName];
        }
      }
    });
  }

  setPLZValidatorForControl(plzControlname:string) {
    if (!this.isSubmitted)
      return;
    this.getFormItem(plzControlname)?.setValidators([
      Validators.maxLength(this.plzHelper.getMaxLength(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value)),
      Validators.pattern(this.plzHelper.getPattern(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value))
    ]);
  }
  getMaxLength(plzControlname:string): number {
    return this.plzHelper.getMaxLength(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value);
  }
  getPattern(plzControlname:string): string {
    return this.plzHelper.getPattern(this.getFormItem(this.plzHelper.getLandforPlz(plzControlname))?.value);
  }

  getTextbausteinOrTranslate(textbausteinKey: KeyString, translateKey: string): string {
    return this.existsSessionStorageTextbausteine?
      this.textbausteinService.printTextbausteinByKey(textbausteinKey, translateKey?
        this.translateService.instant(translateKey) : '') : '';
  }

  protected readonly KeyString = KeyString;
}

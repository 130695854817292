<div class="d-flex justify-content-center ml-4 mr-4 mt-1 mb-1 flex-nowrap">
  <h3 class="colorednavbar-top mb-0"><strong>{{'APPLICATION.SFK-MODUL'|translate}}</strong></h3>
</div>
<nav class="navbar navbar-expand-lg navbar-dark mb-3 colorednavbar py-3 h5 fw-bold">
  <div class="container-fluid">
    <div class="navbar-brand">
      <img
        id="logo"
        [src]="this.safeLogoSource"
        alt="logo"
        style="max-height: var(--logo-height, 50px)"
      >
    </div>
    <button class="navbar-toggler" style="margin-right:5px !important;" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" *ngIf="roleEnabled['Antragsteller']">
          <a class="nav-link textnavbar" [routerLink]="['/pages/home']">
            <fa-icon class="homeIcon" [icon]="faHouse"></fa-icon>
          </a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Antragsteller'] && moduleEnabled['antragTicket']"
            data-cy="nav-item-antraege">
          <a class="nav-link textnavbar"
             [routerLink]="['/pages/ticket-table']"
             data-cy="nav-item-antraege-link"
          >
            {{existsSessionStorageTextbausteine?
              textbausteinService.printTextbausteinByKey( KeyString.ANTRAEGE_VERWALTEN_MENUE, ('HOME.LINK_TICKET_TABLE'|translate) ):('HOME.LINK_TICKET_TABLE'|translate)}}
          </a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Antragsteller'] && moduleEnabled['fahrkosten']"
            data-cy="nav-item-fahrkosten">
          <a class="nav-link textnavbar"
             [routerLink]="['/pages/fahrkosten']"
             data-cy="nav-item-fahrkosten-link"
          >
            {{existsSessionStorageTextbausteine?
              textbausteinService.printTextbausteinByKey( KeyString.FAHRKOSTEN_VERWALTEN_MENUE, ('HOME.LINK_FAHRKOSTEN_TABLE'|translate) ):('HOME.LINK_FAHRKOSTEN_TABLE'|translate)}}
          </a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Antragsteller']"
            data-cy="nav-item-schueler">
          <a class="nav-link textnavbar" [routerLink]="['/pages/schueler-table']" data-cy="nav-item-schueler-link">
            {{existsSessionStorageTextbausteine?
              textbausteinService.printTextbausteinByKey( KeyString.SCHUELER_VERWALTEN_MENUE, ('HOME.LINK_SCHUELER_TABLE'|translate) ):('HOME.LINK_SCHUELER_TABLE'|translate)}}
          </a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Superuser']"
            data-cy="nav-item-konfiguration">
          <a class="nav-link textnavbar" [routerLink]="['/pages/config']" data-cy="nav-item-konfiguration-link">{{"HOME.LINK_KONFIGURATION" | translate}}</a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Superuser']"
            data-cy="nav-item-textbausteine">
          <a class="nav-link textnavbar" [routerLink]="['/pages/textbausteine']" data-cy="nav-item-textbausteine-link">{{"HOME.LINK_TEXTBAUSTEINE" | translate}}</a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Superuser']"
            data-cy="nav-item-schulen">
          <a class="nav-link textnavbar" [routerLink]="['/pages/schulen']" data-cy="nav-item-schulen-link">{{"HOME.LINK_SCHULEN" | translate}}</a>
        </li>
        <li class="nav-item" *ngIf="roleEnabled['Superuser']"
            data-cy="nav-item-schultypen">
          <a class="nav-link textnavbar" [routerLink]="['/pages/schultypen']" data-cy="nav-item-schultypen-link">{{"HOME.LINK_SCHULTYPEN" | translate}}</a>
        </li>
      </ul>

      <ul class="navbar-nav ms-auto">
        <li class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
          <div data-cy="username-portal" *ngIf="configService.featureConfig.kommunalportalAuth" class="d-inline-block">
            <p class="nav-link textnavbar" id="antragsteller-name">
              {{benutzer?.vorname}} {{benutzer?.name}}
            </p>
          </div>
          <div data-cy="username-jwt" *ngIf="configService.featureConfig.jwtAuth" class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
            <a class="nav-link textnavbar" id="dropdownBasic1" ngbDropdownToggle>
              <ng-container id="antragsteller-name">
                {{benutzer?.vorname}} {{benutzer?.name}}
              </ng-container>
            </a>
            <div ngbDropdownMenu class="custom-dropdown-menu" aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem [routerLink]="['/pages/profil']">
                <fa-icon class="me-1" [icon]="faProfil"></fa-icon>
                {{'APPLICATION.MAIN.PROFIL'|translate}}
              </button>
              <button ngbDropdownItem [routerLink]="['/pages/account']">
                <fa-icon class="me-1" [icon]="faAccount"></fa-icon>
                {{'APPLICATION.MAIN.ACCOUNT'|translate}}
              </button>
              <hr class="mb-1 mt-1">
              <button
                ngbDropdownItem
                (click)="logout()"
                data-cy="profile-logout"
              >
                <fa-icon class="me-1" [icon]="faAbmelden"></fa-icon>
                {{'APPLICATION.MAIN.ABMELDEN'|translate}}
              </button>
            </div>
          </div>
        </li>

        <li class="nav-item">
          <a class="nav-link disabled" href="#">
            <app-countdown [endDateObservable$]="this.endDateForLogout$"
                           [loggedoutMessageVisible$]="this.loggedoutMessageVisible$"
                           id="countdown_component">
            </app-countdown>
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>

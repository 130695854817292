<div *ngIf="!(!image)" class="picture-file-zoom" style="position:relative">
  <button class="btn float-bottom-right"><i class="fa fa-download"></i></button>
  <button (click)="download('img')" class=" btn-primary float-bottom-right" >
    <fa-icon
      [icon]="faDownload"
      size="3x"
    ></fa-icon>
    {{'APPLICATION.BUTTON_HERUNTERLADEN' |translate }}
  </button>
  <img  [src]="image" alt="">
</div>

<div *ngIf="!(!pdf)" class="picture-file-zoom" style="position:relative" >
  <button class="btn float-bottom-right"><i class="fa fa-download"></i></button>


  <button (click)="download('pdf')" class=" btn-primary float-bottom-right" >
    <fa-icon
      [icon]="faDownload"
      size="3x"
    ></fa-icon>
    {{'APPLICATION.BUTTON_HERUNTERLADEN' |translate }}
  </button>
  <pdf-viewer [src]="this.pdf"
              [render-text]="false"
              [original-size]="true"
              [fit-to-page]="true"
              [show-borders]="false"
              [zoom]=".9"
              [zoom-scale]="'page-fit'"
              [style]="'width:'+(this.width-50)+'px;height:'+(this.height-50)+'px;'"
  ></pdf-viewer>
</div>

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {SchuleService} from "../../services/schule/schule.service";
import {CustomToastService} from "../../utils/custom-toast.service";
import {faEye, faEyeSlash, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BenutzerService} from "../../services/benutzer/benutzer.service";
import {catchError} from "rxjs/operators";
import {EMPTY} from "rxjs";
import {BenutzerDTO} from "../../../models/benutzer/BenutzerDTO";
import {DatenschutzComponent} from "../../../information/datenschutz/datenschutz.component";
import {createVormundAgeValidator, DateTimeHelperService} from "../../utils/forms/datetime-helper.service";
import {CompositMessageService} from "../../services/compositMessage/compositMessage.service";
import {ModuleConfigService} from "../../services/module-config/module-config.service";
import {SessionService} from "../../services/session/session.service";
import {TextbausteinService} from "../../services/textbaustein/textbaustein.service";
import {KeyString} from "../../../models/textbaustein/KeyString";
import {FieldConfigService} from "../../services/field-config/field-config.service";
import {pwFormatValidator} from "../../validators/formatting/password-format.validator";
import {registrationEmailRepeatValidator} from "../../validators/registration/registration-email-repeat.validator";
import {registrationPwRepeatValidator} from "../../validators/registration/registration-pw-repeat.validator";
import {PFLICHTFELDMARKER} from "../../../../assets/constants/constants";
import {HttpParams} from "@angular/common/http";
import {TextbausteinDTO} from "../../../models/textbaustein/TextbausteinDTO";


@Component({
  selector: 'app-registrierung',
  templateUrl: './registrierung.component.html',
  styleUrls: ['./registrierung.component.scss']
})
export class RegistrierungComponent implements OnInit {

  public registrierungForm: UntypedFormGroup;
  @ViewChild('captchaText') captchaText: ElementRef;
  @ViewChild('captchaEnter') captchaEnter: ElementRef;
  public dto: BenutzerDTO;
  public log: string[] = [];
  schuleList =[];
  faInfo = faInfoCircle;

  pwHide = true;
  faEye = faEye;
  faEyeSlash = faEyeSlash;

  entityNameTranslationKey: string = 'REGISTRIERUNG';

  minDateBirthday : string;
  maxDate : string;

  fieldConfig;
  existsSessionStorageTextbausteine = false;

  constructor(private router: Router,
              public translateService: TranslateService,
              private schuleService: SchuleService,
              private fb: UntypedFormBuilder,
              private modalService: NgbModal,
              private benutzerService: BenutzerService,
              private customToastService: CustomToastService,
              private compositMessageService: CompositMessageService,
              public datetimeHelper: DateTimeHelperService,
              private moduleConfigService: ModuleConfigService,
              private sessionService: SessionService,
              public textbausteinService: TextbausteinService,
              private fieldConfigService: FieldConfigService
  ) {

    this.minDateBirthday = DateTimeHelperService.BIRTHDATE_MINIMUM;

    this.loadFieldConfig();

    this.registrierungForm = this.fb.group({
        name: [''],
        vorname:[''],
        schuleId:[''],
        geburtsdatum:[''],
        email:['',[Validators.email]],
        emailbestaetigung:[''],
        kennwort:['',[pwFormatValidator]],
        kennwortbestaetigung:[''],
        nutzungsbedingungen: [''],
        datenschutz:['']
      },
      { validators: [registrationEmailRepeatValidator,registrationPwRepeatValidator] } as AbstractControlOptions);

    if(this.moduleConfigService.isModuleEnabled('onlyAdultRegistrations')) {
      this.getFormItem('geburtsdatum').addValidators([createVormundAgeValidator]);
      this.getFormItem('geburtsdatum')?.updateValueAndValidity();
    }

  }

  ngOnInit(): void {

    this.schuleService.getSchulenForRegistration()
      .pipe(catchError(()=>{
        this.customToastService.showError("Die Liste der Schulen konnte nicht geladen werden.");
        return EMPTY;
      }))
      .subscribe(schulen => this.schuleList = schulen);

    this.sessionService.watchSessionStorageExistsTextbausteine()
      .subscribe( yesNo => {
        this.existsSessionStorageTextbausteine = yesNo;
      });
    if(!this.existsSessionStorageTextbausteine){
      this.textbausteinService.getTextbausteinList(new HttpParams().set('limit', '10000').set('orderbycolumn','key'))
        .subscribe( (dtoList: TextbausteinDTO[]) => {
          sessionStorage.setItem('textbausteine', JSON.stringify(dtoList));
          this.sessionService.broadcastTextbausteineChange();
        });

    }
  }

  loadFieldConfig(){
    this.fieldConfig = this.fieldConfigService.getFieldConfigFromSessionStorage(['registrationASM']);
    this.maxDate = this.moduleConfigService.isModuleEnabled('onlyAdultRegistrations') ?
      this.datetimeHelper.maxDateVormund() : this.datetimeHelper.formattedToday();
  }

  getFormItem(s: string) {
    return this.registrierungForm?.get(s);
  }

  openDialog(){
    const modalRef = this.modalService.open(DatenschutzComponent, { size: 'lg' , windowClass: 'modal-xl'});
    modalRef.componentInstance.name = 'Datenschutz';
  }

  create() {
    this.applyFieldConfigValidators().then(()=>{
      if(!this.registrierungForm.valid){
        this.customToastService.showError(this.compositMessageService.createIncompleteMessage(this.entityNameTranslationKey));
        return;
      }
      this.finalizeUpdate();
    });
  }

  finalizeUpdate(){
    const emailInput =  this.getFormItem('email')?.value.length !== 0 ? this.getFormItem('email').value : null
    const emailInputBestaetigung = this.getFormItem('emailbestaetigung')?.value.length !== 0 ? this.getFormItem('emailbestaetigung').value : null
    const kennwort = this.getFormItem('kennwort')?.value.length !== 0 ? this.getFormItem('kennwort').value : null
    const kennwortBestaetigung =  this.getFormItem('kennwortbestaetigung')?.value.length !== 0 ? this.getFormItem('kennwortbestaetigung').value : null

    this.benutzerService.createBenutzer({
      name: this.getFormItem('name')?.value.length !== 0 ? this.getFormItem('name').value : null,
      vorname: this.getFormItem('vorname')?.value.length !== 0 ? this.getFormItem('vorname').value : null,
      schuleId: this.getFormItem('schuleId')?.value.length !== 0 ? this.getFormItem('schuleId').value : null,
      geburtsdatum: this.getFormItem('geburtsdatum')?.value.length !== 0 ? this.getFormItem('geburtsdatum').value : null,
      email: emailInput,
      emailbestaetigung: emailInputBestaetigung,
      kennwort: kennwort,
      kennwortbestaetigung: kennwortBestaetigung,
      datenschutz: this.getFormItem('datenschutz')?.value.length !== 0 ? this.getFormItem('datenschutz').value : null,
      nutzungsbedingungen: this.getFormItem('nutzungsbedingungen')?.value.length !== 0 ? this.getFormItem('nutzungsbedingungen').value : null
    })
      .pipe(
        catchError((err) => {
          if(err.error?.message?.includes('user_already_exists'))
            this.customToastService.showError(this.translateService.instant('COMPOSITE-MESSAGE.EMAIL_ALREADY_EXISTS'));
          else
            this.customToastService.showError(this.compositMessageService.registrationFailed(err));
          return EMPTY;
        })
      )
      .subscribe((data: BenutzerDTO) => {
        this.dto = {...data};
        this.customToastService.showSuccess(this.compositMessageService.registrationSucceeded());
        this.redirectToDeadend()
      });

  }

  isVisible(fieldName: string): boolean {
    return this.fieldConfigService.getFieldFromFieldConfig(fieldName, this.fieldConfig)?.visible;
  }

  isRequired(fieldName: string): boolean {
    return this.fieldConfigService.getFieldFromFieldConfig(fieldName, this.fieldConfig)?.required;
  }

  getTextbausteinOrTranslate(textbausteinKey: KeyString, translateKey: string): string {
    return this.existsSessionStorageTextbausteine?
      this.textbausteinService.printTextbausteinByKey(textbausteinKey, translateKey?
        this.translateService.instant(translateKey) : '') : '';
  }

  getFieldLabelOrTranslate(fieldName: string, translateKey: string): string {
    return ''+(this.isRequired(fieldName)?PFLICHTFELDMARKER:'')
      +this.fieldConfigService.getFieldLabel(fieldName, this.fieldConfig, this.translateService.instant(translateKey));
  }

  applyFieldConfigValidators() : Promise<any>{
    return new Promise<void>((resolve)=>{
      if(!(!this.fieldConfig)&&this.fieldConfig.length>0){
        this.fieldConfig.forEach( entry => {
          if(entry.visible&&entry.required){
            this.registrierungForm.get(entry.field).addValidators([Validators.required]);
            this.registrierungForm.get(entry.field).updateValueAndValidity();
          }
        });
      }
      resolve();
    });
  }

  redirectToDeadend(){
    const navigationExtras = {
      state: {
        title: 'Registrierung',
        content: 'Eine E-Mail zum Aktivieren Ihres Benutzerkontos wurde versandt. '
          + 'Bitte prüfen Sie Ihr Postfach: ' + this.registrierungForm?.get('email')?.value
      }
    };
    this.router.navigate(['authentication/info'],navigationExtras);
  }

  protected readonly KeyString = KeyString;

}

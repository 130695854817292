<app-default-layout
  [title]="'Datenschutz'"
>
  <div class="row">
    <div class="col-md-10 offset-md-1 mb-4 pb-4 "
      [innerHTML]="existsSessionStorageTextbausteine?
      this.textbausteinService.printTextbausteinByKey( KeyString.DATENSCHUTZ_TEXT,defaultText)
      : defaultText"
         data-cy="datenschutz-text"
    ></div>
  </div>
  <div class="d-flex flex-row-reverse" *ngIf="activeModal">
  <button type="close"
          class="btn btn-primary save btn-block me-3"
  (click)="closeModal()">
    {{'APPLICATION.BUTTON_SCHLIESSEN'|translate}}

  </button>
  </div>
</app-default-layout>

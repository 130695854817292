import { Component } from '@angular/core';

@Component({
  selector: 'app-deadend',
  standalone: false,
  templateUrl: './deadend.component.html',
  styleUrl: './deadend.component.scss'
})
export class DeadendComponent {

  title: string = '';
  content: string = '';

  ngOnInit() {
    this.title = history.state.title || '';
    this.content = history.state.content || '';
  }

}
